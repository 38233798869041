<template>
  <div>
    <CurrencySelect
      :currencies="currencies"
      v-model="sourceCurrency"
    />

    <input
      type="hidden"
      name="source_currency"
      :value="sourceCurrency"
    />

    <div class="vertical-line-balance mt-4" style="padding-left: 10px;">
      Tu saldo actual es: <span class="font-bold">{{ moneyFormat(-this.selectedBalance.balance, this.selectedBalance.currency) }}</span>
    </div>
  </div>
</template>

<script>
  import { moneyFormat } from '../../../utils/money_format.js';
  import CurrencySelect from '../partials/currency-select';

  export default {
    beforeMount() {
      this.sourceCurrency = this.currencies[0];
    },
    components: {
      CurrencySelect,
    },
    props: {
      balanceAccounts: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        sourceCurrency: '',
        amount: 0,
        moneyFormat,
      };
    },
    computed: {
      amountMask() {
        return {
          allowDecimalPadding: false,
          modifyValueOnWheel: false,
          decimalPlaces: this.sourceCurrency === 'CLP' ? 0 : 2,
          decimalPlacesRawValue: null,
          caretPositionOnFocus: 'end',
          selectOnFocus: false,
          decimalCharacter: ',',
          digitGroupSeparator: '.',
          currencySymbol: ` ${this.sourceCurrency || ''}`,
          currencySymbolPlacement: 's',
          emptyInputBehavior: 'always',
        }
      },
      currencies() {
        return this.balanceAccounts.map((account) => account.currency);
      },
      selectedBalance() {
        return this.balanceAccounts.find((account) => account.currency === this.sourceCurrency);
      },
    },
    methods: {
      handleAmountInput(event) {
        this.amount = event.target.rawValue;
      },
    },
  };
</script>
