<template>
  <div class="sm:px-0" :class="{ 'pb-8': !errorNoRateAvailable }">
    <div id="investment-content" class="lg:py-0 py-8">
      <div v-if="errorNoRateAvailable" class="bank-static-message">
        <div class="arcadi-disclaimer bank-static-message">
          <img :src="images.arcadiAlert" class="w-8 mr-2 inline" />
          {{ $t("investment_page.no_exchange_rate") }}
        </div>
      </div>
      <div v-else>
        <div
          class="flex justify-between items-center ml-4 mb-2 font-color-primary"
        >
          <span> {{ $t("investment_page.amount_to_invest") }}: </span>
          <button
            type="button"
            @click="handleUseMaxBalance"
            class="button-arcadi-simulator-all"
          >
            {{ $t("simulator_page.use_all") }}
          </button>
        </div>

        <div class="flex mb-3 investment-amount-container-body">
          <div class="flex items-center mr-4">
            <v-select
              v-model="sourceCurrency"
              :options="currencies"
              label="name"
              :clearable="false"
              :searchable="false"
              class="flex border-t-0 md:w-2/5 lg:w-1/2"
              appendToBody
            >
              <template
                v-slot:[slotName]="currency"
                v-for="slotName in ['option', 'selected-option']"
              >
                <span
                  class="overflow-visible whitespace-no-wrap"
                  :key="slotName"
                >
                  <img
                    :src="require(`coin_flags/${currency.name}.svg`)"
                    class="inline align-middle flag-style"
                    style="min-width: 34px; max-width: 34px;"
                  />
                </span>
              </template>
              <template #no-options>
                {{ $t("investment_page.no_coin") }}
              </template>
            </v-select>
          </div>
          <div class="w-full">
            <div class="z-40 flex">
              <div style="height: 77px" class="flex flex-col justify-center">
                <input
                  type="tel"
                  inputmode="numeric"
                  :rawvalue="amount"
                  autocomplete="off"
                  @input="handleAmountInput"
                  v-numeric="amountMask"
                  class="w-full shadow-none arcadi-input border-gray-custom rounded-right p-0"
                  style="height: 30px; font-weight: 500; font-size: 24px; background-color: transparent;"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex font-medium text-sm font-color-primary">
          <span class="font-semibold ml-4"
            >{{ $t("investment_page.your_balance") }}:</span
          >
          <format-amount
            :amount="
              this.selectedBalance && Number(selectedBalance.balance)
                ? -this.selectedBalance.balance
                : 0
            "
            :currency="sourceCurrency"
            :minimumFractionDigits="0"
            :maximumFractionDigits="sourceCurrency === 'CLP' ? 0 : 2"
          />
        </div>
        <div
          v-if="errorAmount"
          class="relative z-10 my-3 rounded shadow-md warning-message text-center"
        >
          {{ $t("investment_page.no_money") }}
          <img :src="warningSVG" class="inline w-4 -mt-px" />
        </div>
        <div
          v-else-if="errorMinimumAmount"
          class="relative z-20 my-3 rounded warning-message text-center"
        >
          {{ $t("investment_page.minimum_investment") }}:
          {{
            AutoNumeric.format(
              this.investmentRate.minimumAmountLimit,
              amountMask
            )
          }}
          <img :src="warningSVG" class="inline w-4 -mt-px" />
        </div>
        <div
          v-else-if="errorMaximumAmount"
          class="relative z-30 my-3 rounded warning-message text-center"
        >
          {{ $t("investment_page.maximum_investment") }}:
          {{
            AutoNumeric.format(
              this.investmentRate.maximumAmountLimit,
              amountMask
            )
          }}
          <img :src="warningSVG" class="inline w-4 -mt-px" />
        </div>
        <input type="hidden" name="currency" :value="sourceCurrency" />
        <input type="hidden" name="amount" :value="amount" />
        <p class="ml-4 mb-2 font-color-primary mt-4">
          {{ $t("investment_page.select_term") }}:
        </p>

        <div class="relative slider-container">
          <div class="flex justify-between">
            <div>
              {{ $t("investment_page.term") }}
            </div>
            <div>
              {{ monthLabel }}
            </div>
          </div>
          <input
            id="slider-term"
            v-model="selectedTermIndex"
            type="range"
            min="0"
            :max="(monthTerms.length - 1) * 100"
            step="1"
            value="0"
            class="investment-slider relative z-20 mt-3"
            list="values"
            @change="selectTerm(selectedTermIndex)"
          />
          <div
            class="flex justify-between px-2 relative z-10"
            style="padding-left: 9px; padding-right: 9px; top: 0px"
          >
            <span
              v-for="(term, index) in monthTerms"
              class="slider-step"
              :value="index"
              :label="term.label"
              :key="index"
            ></span>
          </div>
        </div>
        <!-- Repetir para cada elemento en this.availableTerms -->
        <div v-for="(termInfo, index) in availableTerms" :key="index">
          <div
            v-if="isMonthSelected(termInfo.term.value) && initValueAmount"
            :class="{
              'investment-rate-info-selected': index === selectedTermIndexReal,
              'investment-rate-info': index !== selectedTermIndexReal
            }"
            class="flex investment-card my-4 shadow-md"
          >
            <!-- Columna 1: Imagen -->
            <div
              class="flex items-center justify-center hidden md:flex investment-card-image-container rounded-l-3xl px-3"
            >
              <img
                :src="
                  require(`../../../../assets/images/investment-coin-term-${termInfo.term.value}.svg`)
                "
                class="investment-amount-container-image inline w-40"
                :class="{
                  'investment-amount-container-image-one':
                    termInfo.term.value === 'one_month'
                }"
              />
            </div>

            <div class="flex flex-grow p-6 sm:px-4">
              <!-- Columna 2: Información actual -->
              <div class="flex-grow">
                <div class="items-center mb-0 sm:mb-4">
                  <span class="mb-4 text-xl font-medium"
                    >{{ $t("investment_page.term") }}:
                    {{ termInfo.term.label }}</span
                  >
                </div>
                <div class="flex mb-1">
                  {{ $t("investment_page.ea_rate") }}:
                  <format-amount
                    :amount="termInfo.rate.rate * 100"
                    currency="%"
                    :minimumFractionDigits="2"
                    :maximumFractionDigits="2"
                  />
                </div>
                <div class="flex">
                  {{ $t("investment_page.will_receive") }}:
                  <span>
                    <format-amount
                      :amount="totalAmountWithReturn(termInfo)"
                      :currency="sourceCurrency"
                      :minimumFractionDigits="0"
                      :maximumFractionDigits="sourceCurrency === 'CLP' ? 0 : 2"
                    />
                  </span>
                </div>
                <div class="investment-profit-container-wrapper">
                  <div class="investment-profit-container">
                    <div class="investment-profit-bar">
                      <span class="flex ml-3 investment-profit-text">
                        {{ $t("investment_page.profit") }}:
                        <span class="ml-1">
                          <format-amount
                            :amount="returnInvestment(termInfo)"
                            :currency="sourceCurrency"
                            :minimumFractionDigits="0"
                            :maximumFractionDigits="
                              sourceCurrency === 'CLP' ? 0 : 2
                            "
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                  <img
                    :src="require(`../../../../assets/images/estrellas.svg`)"
                    alt="Estrellas"
                    class="inline w-8 estrellas-image hidden sm:block"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <div class="flex items-center">
            <div class="mr-4">
              <input type="checkbox" required v-model="declarationChecked" />
            </div>
            <div class="font-color-primary text-xs">
              {{ $t("investment_page.consent") }}
              <a
                class="font-color-complementary"
                href="/terms_conditions_finance"
                target="_blank"
              >
                {{ $t("investment_page.terms_conditions") }}
              </a>
            </div>
          </div>
        </div>

        <input
          type="hidden"
          name="investment_rate_id"
          :value="selectedRateId"
        />

        <button
          type="submit"
          class="text-white shadow-md arcadi-form__submit arcadi-button investment-button-continue"
          :disabled="
            !isAmountValid ||
              !areRatesAvailable ||
              !isMinimumAmountValid ||
              !isMaximumAmountValid ||
              !declarationChecked
          "
        >
          {{ $t("investment_page.button_continue") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import InvestmentApi from "../../../api/investment";
import CurrencySelect from "../partials/currency-select";
import AutoNumeric from "autonumeric";
import FormatAmount from "../amount/format-amount.vue";

const MAX_RETRY_ATTEMPTS = 3;
const RETRY_DELAY = 1000; // 1000 milisegundos = 1 segundo

export default {
  components: {
    CurrencySelect,
    FormatAmount
  },
  data() {
    return {
      terms: [],
      sourceCurrency: "",
      loading: true,
      investmentRate: {},
      amount: 0,
      AutoNumeric,
      errorAmount: false,
      errorMinimumAmount: false,
      errorMaximumAmount: false,
      errorNoRateAvailable: false,
      balanceAccountsWithRates: [],
      warningSVG: require("../../../../assets/images/warning.svg"),
      availableTerms: [],
      selectedTermIndex: 0,
      selectedTermIndexReal: 0,
      selectedRateId: null,
      retryAttempts: 0,
      declarationChecked: false,
      monthTerms: [],
      initValueAmount: false,
      images: {
        arcadiAlert: require("../../../../assets/images/arcadi-alert.svg")
      }
    };
  },
  mounted() {
    this.fetchTerms();
    this.filterBalanceAccountsByCurrenciesWithRates();
  },
  props: {
    balanceAccounts: {
      type: Array,
      required: true
    }
  },
  watch: {
    // whenever sourceCurrency changes, this function will run
    sourceCurrency(newSourceCurrency, oldSourceCurrency) {
      if (newSourceCurrency != oldSourceCurrency) {
        this.handleCurrencyChange();
      }
    },
    availableTerms(newAvailableTerms, _oldAvailableTerms) {
      this.monthTerms = [];
      newAvailableTerms.forEach((term, _index) => {
        this.monthTerms.push({
          value: term.term.value,
          label: term.term.label
        });
      });
    },
    selectedTermIndex(newIndex, _oldIndex) {
      const progress = (newIndex / ((this.monthTerms.length - 1) * 100)) * 100;
      const slider = document.getElementById("slider-term");
      slider.style.background = `linear-gradient(to right, #611de9 ${progress}%, #ccc ${progress}%)`;
    }
  },
  methods: {
    isMonthSelected(month) {
      if (this.monthTerms.length === 0) return false;
      const availableMonths = this.monthTerms[this.selectedTermIndexReal];
      return availableMonths.value === month;
    },

    fetchTerms() {
      InvestmentApi.getTerms()
        .then(response => {
          this.terms = response.data;
        })
        .catch(error => {
          console.error("Error fetching terms:", error);
        });
    },

    async filterBalanceAccountsByCurrenciesWithRates() {
      this.balanceAccountsWithRates = this.balanceAccounts;
      const promises = this.balanceAccounts.map(async account => {
        const { data } = await InvestmentApi.getInvestmentRatesByCurrency(
          account.currency
        );
        return { account, hasRate: data != null };
      });

      const results = await Promise.all(promises);

      for (let i = this.balanceAccountsWithRates.length - 1; i >= 0; i--) {
        if (!results[i].hasRate) {
          this.balanceAccountsWithRates.splice(i, 1);
        }
      }

      if (this.balanceAccountsWithRates.length > 0) {
        this.sourceCurrency = this.currencies[0];
      } else {
        this.errorNoRateAvailable = true;
      }
    },

    async handleCurrencyChange() {
      let termsWithRates = await Promise.all(
        this.terms.map(async term => {
          const { data } = await InvestmentApi.getInvestmentRates(
            this.sourceCurrency,
            term.value
          );
          if (data !== null) {
            return { term, rate: data };
          }
          return null;
        })
      );
      this.availableTerms = termsWithRates.filter(result => result !== null);

      if (this.availableTerms && this.availableTerms.length > 0) {
        this.selectTerm(0);
      } else {
        // Agregar alguna lógica para evitar un bucle infinito, por ejemplo, un contador de intentos
        if (this.retryAttempts < MAX_RETRY_ATTEMPTS) {
          this.retryAttempts++;
          console.log(`Retrying... Attempt ${this.retryAttempts}`);
          // Vuelve a llamar a la función después de un breve tiempo (puedes ajustar el tiempo según tus necesidades)
          setTimeout(() => {
            this.handleCurrencyChange();
          }, RETRY_DELAY);
        } else {
          console.error(
            "Se superó el número máximo de intentos. Deteniendo la recursión."
          );
        }
      }
    },

    handleAmountInput(event) {
      this.initValueAmount = true;
      this.amount = event.target.autonumeric.rawValue;
    },
    handleUseMaxBalance(_event) {
      this.amount = -this.selectedBalance.balance;
      if (this.amount > 0) this.initValueAmount = true;
      
    },
    selectTerm(index) {
      index = Math.round(parseInt(index) / 100);
      this.selectedTermIndexReal = index;
      this.selectedTermIndex = index * 100;
      this.selectedRateId = this.availableTerms[index].rate.id;
      this.investmentRate = this.availableTerms[index].rate;
    }
  },
  computed: {
    monthLabel() {
      if (this.monthTerms.length === 0) return "";
      return this.monthTerms[this.selectedTermIndexReal].label;
    },
    currencies() {
      return this.balanceAccountsWithRates.map(account => account.currency);
    },
    selectedBalance() {
      return this.balanceAccountsWithRates.find(
        account => account.currency === this.sourceCurrency
      );
    },
    returnInvestment() {
      return termInfo => {
        if (!termInfo) {
          return 0;
        }

        const monthlyRate = termInfo.rate.rate / 12;
        const returnRate = monthlyRate * termInfo.term.months;
        const totalReturn = this.amount * returnRate;

        return totalReturn > 0 ? totalReturn : 0;
      };
    },
    totalAmountWithReturn() {
      return termInfo => {
        if (!termInfo) {
          return 0;
        }

        const total =
          parseFloat(this.amount) + parseFloat(this.returnInvestment(termInfo));
        return isNaN(total) ? 0 : total;
      };
    },
    amountMask() {
      return {
        allowDecimalPadding: false,
        modifyValueOnWheel: false,
        decimalPlaces: this.sourceCurrency === "CLP" ? 0 : 2,
        decimalPlacesRawValue: null,
        caretPositionOnFocus: "end",
        selectOnFocus: false,
        decimalCharacter: this.$i18n.locale === "en" ? "." : ",",
        digitGroupSeparator: this.$i18n.locale === "en" ? "," : ".",
        currencySymbol: ` ${this.sourceCurrency || ""}`,
        currencySymbolPlacement: "s",
        emptyInputBehavior: "always"
      };
    },
    isAmountValid() {
      if (this.selectedBalance) {
        this.errorAmount = this.amount > -this.selectedBalance.balance;
      } else {
        this.errorAmount = false;
      }
      return this.amount > 0 && !this.errorAmount;
    },
    areRatesAvailable() {
      return !!this.investmentRate;
    },
    isMinimumAmountValid() {
      this.errorMinimumAmount =
        this.amount < this.investmentRate.minimumAmountLimit;
      return this.amount > 0 && !this.errorMinimumAmount;
    },
    isMaximumAmountValid() {
      this.errorMaximumAmount =
        this.amount > this.investmentRate.maximumAmountLimit;
      return this.amount > 0 && !this.errorMaximumAmount;
    },
    cleanAmount() {
      if (typeof this.amount === "string") {
        return this.amount.replace(".", ",");
      }
      return this.amount;
    }
  }
};
</script>
<style scoped>
.slider-container {
  display: flex;
  flex-direction: column;
  border-radius: 27px;
  background: #fff;
  padding: 20px 30px;
  height: 89px;
}

.investment-slider {
  width: 100%;
  accent-color: #611de9;
}

.slider-step {
  border-radius: 0px 0px 2px 2px;
  background: #d9d9d9;
  width: 4px;
  height: 9px;
  flex-shrink: 0;
}

input[type="range"].investment-slider {
  width: 100%;
  accent-color: #611de9;
  -webkit-appearance: none;
  background: #ddd;
  /* Color de fondo del slider */
  border-radius: 10px;
  outline: none;
  width: 100%;
  height: 8px;
  background: #ddd;
  /* Color de la pista del slider */
  border-radius: 10px;
}

input[type="range"].investment-slider::-webkit-slider-thumb {
  position: relative;
  top: 0px;
  -webkit-appearance: none;
  width: 22px;
  height: 22px;
  background: #611de9;
  /* Color del botón del slider */
  border-radius: 50%;
  cursor: pointer;
}

.investment-card {
  border-radius: 30.146px;
  border: 2px solid #000;
  background: var(--color-white);
}

.investment-card-image-container {
  background-image: url("../../../../assets/images/background-card-invest.svg");
  background-size: cover;
  width: 146px;
}

.investment-amount-container-image {
  max-height: 100px;
  max-width: 110px;
}

.investment-amount-container-image-one {
  max-height: 100px;
  max-width: 95px;
}

@media (max-width: 600px) {
  .investment-rate-info-selected {
    box-shadow: 2px 2px 0px 1px #611de9 !important;
  }
}

.arcadi-card .vs__dropdown-toggle,
.arcadi-card .vue__arcadi-input {
  height: 77px !important;
  background-color: #f9f9f9 !important;
  -webkit-border-radius: 0.6rem 0 0 0.6rem !important;
  -moz-border-radius: 0.6rem 0 0 0.6rem !important;
  border-radius: 32px 0 0 32px !important;
}

#investment-content >>> .vs__dropdown-toggle {
  border: none;
  border-radius: 0;
  background: transparent;
}

.arcadi-card >>> .vs__selected-options {
  min-width: 35px;
  margin-left: 10px;
  margin-right: 10px;
}

.v-select {
  min-width: 65px;
}

.investment-rate-info-selected {
  background-color: #f9f9f9;
  border-radius: 32px;
  box-shadow: 2px 2px 0px 1px #611de9 !important;
}

.investment-rate-info {
  background-color: #fff;
  border-radius: 32px;
}

.arcadi-card >>> .vs__dropdown-menu {
  min-width: 77px;
  width: auto;
  width: 100%;
}

.arcadi-card .warning-message {
  background-color: #f19191;
  -webkit-border-radius: 0.6rem;
  -webkit-border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius: 0.6rem;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-topright: 0;
  border-radius: 0.6rem;
  box-shadow: 2px 3px 0px 0px #e1374b;
  height: 20px;
  font-size: 14px;
}

.investment-amount-container-title {
  padding: 46px 6px 8px 20px;
  color: #000;
  font-weight: 400;
}

.investment-amount-container-body {
  border-radius: 27px;
  border-left: 6px solid #611de9;
  background: #f9f9f9;
  height: 77px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.investment-select-term-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 30px;
  padding: 0px 30px;
}

.investment-amount-your-balance {
  font-size: 16px;
  line-height: normal;
}

.investment-profit-container-wrapper {
  display: flex;
  align-items: center;
}

.estrellas-image {
  margin-left: 10px;
  /* Espacio entre el contenedor de progreso y la imagen */
}

.investment-profit-container {
  width: 90%;
  background-color: #f0f0f0;
  border-radius: 13px;
  overflow: hidden;
  -webkit-box-shadow: 2px 3px 0px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 3px 0px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 3px 0px 0px rgba(0, 0, 0, 0.75);
  border: 1px solid #000;
  position: relative;
  margin-top: 10px;
}

.investment-profit-bar {
  height: 24px;
  background-color: #00ff2a;
  position: relative;
}

.investment-profit-text {
  position: absolute;
  white-space: nowrap;
  margin: -2px;
  color: #611de9;
  line-height: 27px;
  margin-left: 18px;
}

.investment-button-continue {
  background-color: #611de9;
}

.investment-button-continue:disabled {
  background-color: #d1d1d1;
}

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radio-item input[type="radio"] {
  display: none;
}

.radio-item label {
  color: #666;
  font-weight: normal;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #611de9;
  background-color: transparent;
}

.radio-item input[type="radio"]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: #611de9;
}

@media only screen and (max-width: 768px) {
  /* Estilos para la versión móvil */
  .investment-amount-container-title {
    line-height: normal;
  }

  .investment-select-term-title {
    font-size: 24px;
  }

  .investment-rate-info-selected,
  .investment-rate-info {
    transition: border-left-color 0.3s ease;
  }

  .investment-profit-container {
    width: 100%;
  }
}
</style>
