import api from './index';

export default {
  getMovements(currency, limit) {
    return api({
      method: 'get',
      url: '/dashboard/movements_info',
      params: {
        currency,
        limit,
      },
    });
  },
};
