var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"arcadi-page arcadi-transfer"},[_c('div',{staticClass:"arcadi-card"},[_c('div',{staticClass:"flex arcadi-card__title"},[_vm._v("\n      "+_vm._s(_vm.$t("buy_dollars_page.show_title"))+"\n      "),_c('img',{staticClass:"inline align-middle ml-3",staticStyle:{"min-width":"40px","max-width":"40px"},attrs:{"src":_vm.images.order}})]),_vm._v(" "),_c('div',[_c('div',{staticClass:"flex flex-col justify-center mb-6 p-2 transfer-amount-container-body transfer-amount-container-result"},[_c('div',{staticClass:"flex items-center mb-1"},[_c('div',{staticClass:"px-4"},[_c('img',{staticClass:"inline align-middle flag-style",staticStyle:{"min-width":"34px","max-width":"34px"},attrs:{"src":_vm.sourceImagePath}})]),_vm._v(" "),_c('div',{staticClass:"w-full px-3"},[_c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.payMessage))])]),_vm._v(" "),_c('div',{staticStyle:{"font-weight":"500","font-size":"24px"}},[_vm._v("\n              "+_vm._s(_vm.AutoNumeric.format(_vm.order.source_amount, _vm.sourceAmountMask()))+"\n            ")])])])])]),_vm._v(" "),_c('div',{staticClass:"flex flex-col justify-center mb-4 p-2 transfer-amount-container-body transfer-amount-container-result"},[_c('div',{staticClass:"flex items-center mb-1"},[_c('div',{staticClass:"px-4"},[_c('img',{staticClass:"inline align-middle flag-style",staticStyle:{"min-width":"34px","max-width":"34px"},attrs:{"src":_vm.coinImage}})]),_vm._v(" "),_c('div',{staticClass:"w-full px-3"},[_c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.receiveMessage))])]),_vm._v(" "),_c('div',{staticStyle:{"font-weight":"500","font-size":"24px"}},[_vm._v("\n            "+_vm._s(_vm.AutoNumeric.format(
                _vm.order.destination_amount,
                _vm.destinationAmountMask()
              ))+"\n          ")])])])]),_vm._v(" "),_c('div',{staticClass:"ml-8 mb-1 font-color-primary"},[_vm._v("\n      "+_vm._s(_vm.buyDollarsMessage)+"\n    ")]),_vm._v(" "),_c('div',[_c('div',{staticClass:"order-form__info-container space-y-2"},[_c('div',{staticClass:"order-form__info"},[_c('span',[_vm._v(_vm._s(_vm.$t("buy_dollars_page.exchange_rate"))+":")]),_vm._v(" "),(Number(_vm.order.rate) > 1)?_c('span',{staticClass:"whitespace-no-wrap"},[_vm._v("\n            1 "+_vm._s(_vm.order.source_amount_currency)+" =\n            "+_vm._s(_vm.AutoNumeric.format(
                _vm.exchangeRateFeeles() || 0,
                _vm.destinationAmountMask()
              ))+"\n          ")]):_c('span',{staticClass:"whitespace-no-wrap"},[_vm._v("\n            1 "+_vm._s(_vm.order.destination_amount_currency)+" =\n            "+_vm._s(_vm.AutoNumeric.format(
                1 / (_vm.exchangeRateFeeles() || 1),
                _vm.sourceAmountMask()
              ))+"\n          ")])]),_vm._v(" "),_c('hr',{staticClass:"w-full text-gray-10% mb-2"}),_vm._v(" "),_c('div',{staticClass:"order-form__info"},[_c('span',[_vm._v(_vm._s(_vm.$t("buy_dollars_page.commission"))+":")]),_vm._v(" "),_c('span',{staticClass:"whitespace-no-wrap"},[_vm._v("\n            "+_vm._s(_vm.AutoNumeric.format(_vm.commission(), _vm.sourceAmountMask()))+"\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"flex justify-between font-footer"},[_c('div',[_vm._v("\n          "+_vm._s(new Date(_vm.order.created_at).toLocaleDateString())+"\n        ")]),_vm._v(" "),_c('div',[_c('span',[_vm._v("Id:")]),_vm._v(" "),_c('span',{staticClass:"whitespace-no-wrap"},[_vm._v("\n            "+_vm._s(_vm.order.id)+"\n          ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }