function toCents(amount, subUnitToUnit) {
  return amount * subUnitToUnit;
}

function unmaskedValueToCents(mask) {
  return toCents(mask.unmaskedValue, mask.masked.blocks.num.subunitToUnit);
}

function humanizeCurrency(currency) {
  if(currency === 'VES') {
    return 'Bs';
  }

  return currency;
}

export { toCents, unmaskedValueToCents, humanizeCurrency };
