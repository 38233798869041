<template>
  <div class="flex items-center px-1">
    <i18n-n
      tag="div"
      class="flex items-baseline"
      :value="amount"
      :format="{ key: 'currency', currency: 'EUR', maximumFractionDigits: maximumFractionDigits, minimumFractionDigits: minimumFractionDigits }"
    > 
      <template #currency="slotProps">
        <span class="hidden">{{ slotProps.currency }}</span>
      </template>
      <template #integer="slotProps">
        <div :style="numberStyle">{{ slotProps.integer }}</div>
      </template>
      <template #group="slotProps">
        <span>{{ slotProps.group }}</span>
      </template>
      <template #fraction="slotProps">
        <span :style="numberStyle + ' font-size: small'">{{ slotProps.fraction }}</span>
      </template>
    </i18n-n>
    <div :style="numberStyle" class="pl-1">{{ currencyUpperCase }}</div>
  </div>
</template>
<script>
export default {
  name: "FormatAmount",
  props: {
    amount: {
      type: Number,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    minimumFractionDigits: {
      type: Number,
      default: 0
    },
    maximumFractionDigits: {
      type: Number,
      default: 0
    },
    numberStyle: {
      type: String,
      default: ""
    }
  },
  computed: {
    currencyUpperCase() {
      if(this.currency === "Bs") return this.currency
      return this.currency.toUpperCase();
    }
  }
};
</script>
