<template>
  <div>
    <div>
      <span class="font-color-primary px-4">
        {{ $t('deposit.flow.amount_deposit') }}:
      </span>
      <div class="flex flex-col mb-3 amount-flow-container mt-1">
        <div class="flex">
          <div class="w-full flex items-center px-6">
            <span class="overflow-visible whitespace-no-wrap">
              <img
                :src="images.clpCoin"
                class="inline align-middle flag-style"
                style="min-width: 34px; max-width: 34px"
              />
            </span>
            <input
              type="tel"
              inputmode="numeric"
              :rawvalue="amountToPay"
              autocomplete="off"
              class="w-full arcadi-input shadow-none border-gray-custom rounded-right bg-transparent"
              style="height: 77px; font-weight: 500; font-size: 24px"
              v-numeric="sourceAmountMask"
              @input="handleAmountInput"
            />
          </div>
        </div>
      </div>
      <div class="table-flow-details p-8 my-6">
        <div class="flex justify-between relative">
          <span class="flex items-center">
            <span class="icons-detail">
              +
            </span>
            <span class="px-2">{{ $t('deposit.flow.flow_commision') }}:</span>
          </span>
          <span class="flex items-center">
            ${{ AutoNumeric.format(commisionFlow, detailAmountMask) }} (3.19%)
            <span style="position: absolute; right: -28px" class="tooltip">
              <span style="margin-left: -100px; " class="tooltiptext">
                {{ tooltips.commission }}
              </span>
              <img
                class="tooltip-image tooltip-image-primary px-2"
                :src="images.infoIcon"
              />
            </span>
          </span>
        </div>
        <hr style="color: #f9f9f9" class="w-full my-2" />
        <div class="flex justify-between">
          <span class="flex items-center">
            <span class="icons-detail">
              +
            </span>
            <span class="px-2">IVA:</span>
          </span>
          <span> ${{ AutoNumeric.format(totalIva, detailAmountMask) }} </span>
        </div>
        <hr class="w-full text-gray-10% my-2" />
        <div class="flex justify-between">
          <span class="flex items-center">
            <span class="icons-detail">
              =
            </span>
            <span class="px-2">{{ $t('deposit.flow.total_commission') }}:</span>
          </span>
          <span>
            ${{ AutoNumeric.format(totalCommision, detailAmountMask) }}
          </span>
        </div>
      </div>
      <span class="font-color-primary px-4 flex items-center">
        {{ $t('deposit.flow.amount_to_pay') }}
        <span class="tooltip">
          <span class="tooltiptext"> {{ tooltips.totalToPay }} </span>
          <img class="tooltip-image tooltip-dynamic px-2" :src="images.infoIcon" />
        </span>
      </span>
      <div class="total-paid-container flex items-center mt-1">
        <span class="overflow-visible whitespace-no-wrap">
          <img
            :src="images.clpCoin"
            class="inline align-middle flag-style"
            style="min-width: 34px; max-width: 34px"
          />
        </span>
        <span class="p-4">
          {{ AutoNumeric.format(flowCharge, sourceAmountMask) }}
        </span>
      </div>
      <div>
        <button
          class="text-white shadow-md arcadi-form__submit arcadi-button"
          @click="redirectFlow()"
        >
        {{ $t('deposit.flow.button_pay') }}
        </button>
      </div>
    </div>
    <div class="arcadi-disclaimer mt-4">
      <img :src="images.arcadiAlert" class="w-8 mr-2 inline" />
      <span v-html="$t('deposit.flow.alert_html')"></span>
    </div>
  </div>
</template>

<script>
import AutoNumeric from "autonumeric";

export default {
  data() {
    return {
      amountToPay: 0,
      commision: 0.0319,
      flowConstant: 0.962039,
      images: {
        arcadiAlert: require("../../../../assets/images/arcadi-alert.svg"),
        clpCoin: require("../../../../assets/images/coin_flags/CLP.svg"),
        infoIcon: require("../../../../assets/images/info-icon.svg")
      },
      tooltips: {
        commission: this.$t('deposit.flow.tooltip_commission'),
        totalToPay: this.$t('deposit.flow.tooltip_pay')
      },
      AutoNumeric
    };
  },
  computed: {
    flowCharge() {
      return Math.round(this.amountToPay / (this.flowConstant));
    },
    totalToPay() {
      return this.totalCommision + parseInt(this.amountToPay);
    },
    commisionFlow() {
      return Math.round(this.flowCharge * this.commision);
    },
    sourceAmountMask() {
      return {
        allowDecimalPadding: "floats",
        modifyValueOnWheel: false,
        decimalPlaces: 0,
        decimalPlacesRawValue: null,
        caretPositionOnFocus: "end",
        selectOnFocus: false,
        decimalCharacter: ",",
        digitGroupSeparator: ".",
        currencySymbol: ` CLP`,
        currencySymbolPlacement: "s",
        emptyInputBehavior: "always"
      };
    },
    detailAmountMask() {
      return {
        allowDecimalPadding: "floats",
        modifyValueOnWheel: false,
        decimalPlaces: 0,
        decimalPlacesRawValue: null,
        caretPositionOnFocus: "end",
        selectOnFocus: false,
        decimalCharacter: ",",
        digitGroupSeparator: ".",
        currencySymbolPlacement: "s",
        emptyInputBehavior: "always"
      };
    },
    totalIva() {
      return Math.round(this.commisionFlow * 0.19);
    },
    totalCommision() {
      return this.commisionFlow + this.totalIva;
    }
  },
  methods: {
    handleAmountInput(event) {
      this.amountToPay = event.target.autonumeric.rawValue ? parseInt(event.target.autonumeric.rawValue) : 0;
    },
    redirectFlow() {
      window.location.href = `/flow_payments/new?amount=${this.totalToPay}`;
    }
  },
  mounted() {
    this.amountToPay = AutoNumeric.format(0);
  }
};
</script>

<style scoped>
.amount-flow-container {
  border-radius: 27px;
  border-left: 6px solid #752eff;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.table-flow-details {
  border-radius: 27px;
  background: #f9f9f9;
}

.total-paid-container {
  border-radius: 27px;
  border-left: 6px solid #00ff49;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 77px;
  font-weight: 500;
  font-size: 24px;
  padding: 0 20px;
}

.tooltip-image {
  max-width: 30px;
  max-height: 30px;
}

.tooltip-image-primary {
  filter: brightness(0) saturate(100%)
}

.tooltip-image-primary:hover {
  filter: brightness(0) saturate(100%) invert(15%) sepia(86%) saturate(7184%) hue-rotate(263deg) brightness(91%) contrast(100%);
}

.tooltip-image-secondary {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.tooltip-image-secondary:hover {
  filter: brightness(0) saturate(100%) invert(68%) sepia(82%) saturate(1336%) hue-rotate(74deg) brightness(98%) contrast(125%);
}



.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  color: #611de9;
  text-align: center;
  border-radius: 6px;
  padding: 12px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 20.452px;
  background: #00ff2a;
  font-size: 11px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.icons-detail {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(217, 217, 217, 0.40);;
  color: black;
  font-size: 14px;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  border: 1px solid black;
  box-shadow: 1px 1px 0px 0px black;
  padding-top: 1px;
}
</style>
