document.addEventListener('DOMContentLoaded', () => {
  function registerDropdownEvent(userToggle) {
    userToggle.addEventListener('click', (event) => {
      event.stopPropagation();
      const dropdown = document.querySelector('.arcadi-header-dropdown');
      dropdown.style.display = 'block';
      document.addEventListener('click', () => {
        dropdown.style.display = 'none';
        registerDropdownEvent(userToggle);
      }, { once: true });
    }, { once: true });
  }

  const userToggle = document.querySelector('.arcadi-header__user-toggle');
  if (userToggle) {
    registerDropdownEvent(userToggle);
  }
});
