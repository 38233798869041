<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container-dashboard" :class="[{'modal-error': isError}]">
          <div v-if="loading && !isError" class="loader-container">
            <div class="loader"></div>
          </div>
          <div v-else>
            <div class="modal-close">
              <slot name="close-button">
                <button class="modal-default-button" @click="closeModal"><span class="material-icons-sharp">cancel</span></button>
              </slot>
            </div>
            <div class="modal-header" style="text-align: left;">
              <h3 class="text-2xl" :class="[{'title-text-success': isSuccess, 'title-text-error': isError}]">{{ title }}</h3>
            </div>
            <div class="mt-4">
              <p v-html="flashMessage" class="flex-auto"></p>
              <div class="mt-6 modal-footer">
                <button v-show="isSuccess" class="text-white shadow-md arcadi-button" @click="reloadPage">{{ $t('dashboard.modal.button_accept') }}</button>
                <a v-show="isError" target="_blank" :href="`https://wa.me/${whatsappNumber}`" class="whatsapp-contact-button" style="margin-left: 0px;">
                  <span>{{ $t('dashboard.modal.whatsapp') }}</span>
                  <img :src="images.whatsapp" class="inline-block w-4" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    show: Boolean,
    flashMessage: String,
    type: {
      type: String,
      default: 'success',
      required: false,
    },
    whatsappNumber: {
      type: String,
      default: '56948428139',
      required: false,
    },
  },
  data() {
    return {
      loading: true,
      images: {
        whatsapp: require('../../../../assets/images/whatsapp-icon.svg'),
      }
    };
  },
  created() {
    this.type = this.type || 'success';
    setTimeout(() => {
      this.loading = false;
    }, 3000);
  },
  computed: {
    isError() {
      return this.type === 'error';
    },
    isSuccess() {
      return this.type === 'success';
    },
    title() {
      const titles = {
        success: this.$t('dashboard.modal.congratulations'),
        error: this.$t('dashboard.modal.error'),
      };
      return titles[this.type];
    },
  },
  methods: {
    closeModal() {
      this.reloadPage();
      this.$emit('close');
    },
    reloadPage() {
      location.reload(true); // Recargar la página forzando la recarga desde el servidor
    },
  },
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container-dashboard {
  width: 30%;
  margin: 0px auto;
  padding: 40px 30px;
  background-color: #fff;
  border-radius: 32px;
  border: 3px solid #1E2123;
  box-shadow: 4px 4px 0px 0px #611DE9;
  transition: all 0.3s ease;
  min-height: 230px;
}

.modal-error {
  box-shadow: 4px 4px 0px 0px #E1374B;
}

@media (max-width: 1200px) {
  .modal-container-dashboard {
    width: 70%;
  }
}

@media (max-width: 600px) {
  .modal-container-dashboard {
    width: 90%;
  }
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
  font-size: small;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container-dashboard,
.modal-leave-to .modal-container-dashboard {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.close {
  border: none;
  font-size: 30px;
  margin-left: 100px;
  cursor: pointer;
  font-weight: bold;
  color: #4AAE9B;
  background: transparent;
}

.modal-body {
  margin: 20px 0;
  text-align: center; /* Añade esto para centrar el contenido */
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
}

.whatsapp-contact-button{
  border-width: 2px;
  box-shadow: 3px 6px 0px 0px #00FF2A;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--border-opacity));
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 9999px;
  padding: 0.75rem;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
  font-size: 16px;
  font-weight: 400;
}

.loader {
  width: 60px;
  aspect-ratio: 1;
  background: linear-gradient(#dc1818 0 0) bottom/100% 0% no-repeat #ccc;
  -webkit-mask:
    radial-gradient(circle at 60% 65%, #000 62%, #0000 65%) top left,
    radial-gradient(circle at 40% 65%, #000 62%, #0000 65%) top right,
    linear-gradient(to bottom left, #000 42%,#0000 43%) bottom left ,
    linear-gradient(to bottom right,#000 42%,#0000 43%) bottom right;
  -webkit-mask-size: 50% 50%;
  -webkit-mask-repeat: no-repeat;
  animation: l19 2s infinite linear;
  margin: 0 auto;
}

.title-text-success {
  color: #611DE9;
}

.title-text-error {
  color: #E1374B;
}

@keyframes l19 {
    90%,100% {background-size:100% 100%}
}
</style>
