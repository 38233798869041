import api from './index';

export default {
getAllCountries(isBank) {
    return api({
      method: 'get',
      url: `/api/v2/all_countries?q[is_asset_eq]=${isBank ? '0' : '1'}`,
    });
  },
  getBanksByCountry(countryId) {
    return api({
      method: 'get',
      url: `/destination_account_banks?country_id=${countryId}`,
    });
  }
};