import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es';

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.js-arcadi-file-input').forEach((node) => {
    const fileInput = node.querySelector('.js-arcadi-input-file');
    const fileValue = node.querySelector('.js-arcadi-input-value');
    if (fileInput) {
      fileInput.addEventListener('input', (event) => {
        const filename = event.target.files ? event.target.files[0].name : undefined;
        fileValue.innerHTML = filename;
      });
    }
  });

  const issueSelectorContainer = document.querySelector('.arcadi-select-transfer-issue');
  const issueInput = document.querySelector('.arcadi-input-transfer-issue');
  const issueInputContainer = document.querySelector('.arcadi-task-error');

  if (issueSelectorContainer && issueInput) {
    const issueSelector = issueSelectorContainer.children[0];
    issueInput.value = issueSelector.value;

    issueSelector.addEventListener('change', ({ target: { value } }) => {
      const otherValue = issueInput.getAttribute('data-dependent-value');

      if (value === otherValue) {
        issueInput.value = '';
        issueInputContainer.removeAttribute('hidden');
      } else {
        issueInputContainer.setAttribute('hidden', true);
        issueInput.value = value;
      }
    });
  }

  function showModal() {
    const loadingBackground = document.createElement('DIV');
    loadingBackground.className = 'arcadi-loading__background';
    const loadingModal = document.createElement('DIV');
    loadingModal.className = 'arcadi-loading__modal';
    loadingBackground.appendChild(loadingModal);
    const loadingDiv = '<div class="mt-6"><div class="loader"><div></div><div></div><div></div><div></div></div></div>';
    loadingModal.innerHTML = `${loadingDiv} Cargando...`;
    const body = document.querySelector('body');
    body.appendChild(loadingBackground);
  }

  const form = document.querySelector('.arcadi-form--loading');
  if (form) form.addEventListener('submit', showModal);

  const today = new Date();
  const MIN_AGE = 18;

  flatpickr(
    '.arcadi-input--date',
    {
      locale: Spanish,
      disableMobile: 'true',
      altFormat: 'd \\de F \\de Y',
      altInput: true,
      dateFormat: 'Y-m-d',
      maxDate: new Date(today.getFullYear() - MIN_AGE, today.getMonth(), today.getDate()),
    }
  );

  const pepExplainToggle = document.querySelector('#pep-explain-toggle');
  if (pepExplainToggle) {
    pepExplainToggle.addEventListener('click', () => {
      document.querySelector('#pep-explanation').toggleAttribute('hidden');
    });
  }
});
