<script>
export default {
  props: {
    show: Boolean,
    hash: String
  },
  data() {
    return {
      copyTextSVG: require('../../../../assets/images/copy_text.svg')
    }
  },
  methods: {
  },
}
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container-wallet">
          <div class="modal-close">
            <slot name="close-button">
              <button
                class="modal-default-button"
                @click="$emit('close')"
              >X</button>
            </slot>
          </div>
          <div class="modal-header">
            <h3>Hash:</h3>

          </div>

          <div class="modal-body modal-header wallet-addresses">
            <p class="flex-auto">{{ hash }}</p>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container-wallet {
  width: 70%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

@media (max-width: 480px) {
  .modal-container-wallet {
    margin: 0;
  }
}

.modal-header h3 {
  margin-top: 0;
  font-weight: bold;
}

.modal-body {
  margin: 20px 0;
}

.modal-body p{
  font-size: small;
}

.modal-body p span{
  font-weight: bold;
}

.modal-default-button {
  float: right;
  font-size: larger;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container-wallet,
.modal-leave-to .modal-container-wallet {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.close {
  border: none;
  font-size: 30px;
  margin-left: 100px;
  cursor: pointer;
  font-weight: bold;
  color: #4AAE9B;
  background: transparent;
}
</style>
