<template>
  <div>
    <div class="form-group flex flex-col pb-4">
      <div v-show="updatability || isBank">
        <label for="destination_account_category_bank" class="edit-profile_form_label">
          <input type="radio" id="destination_account_category_bank" name="destination_account[category]" value="bank"
            v-model="form.category" checked />
          {{ $t("add_contact_page.bank_account") }}
        </label>
      </div>
      <div v-show="updatability || !isBank">
        <input type="radio" id="destination_account_category_wallet" name="destination_account[category]"
          v-model="form.category" value="wallet" />
        <label for="destination_account_category_wallet" class="edit-profile_form_label">{{
        $t("add_contact_page.crypto_account") }}</label>
      </div>
    </div>

    <div v-show="!updatability" class="bank-static-message">
      <div class="arcadi-disclaimer mb-8 bank-static-message">
        <img :src="images.arcadiAlert" class="w-8 mr-2 inline" />
        <span v-html="$t('add_contact_page.form.not_updatability')"></span>
      </div>
    </div>

    <div v-show="updatability" class="mb-4 destination-account_form_instructions">
      {{ instructionsText }}
    </div>
    <div class="form-group">
      <label for="destination_account_alias" class="edit-profile_form_label">
        {{ aliasLabel }}
      </label>
      <input v-model="form.alias" type="text" class="arcadi-input mt-2"
        :class="{ 'mb-4 arcadi-form__input ': !errors.alias }" id="destination_account_alias"
        name="destination_account[alias]" :placeholder="aliasLabel" :disabled="!updatability" />
      <span class="error-destination" v-if="errors.alias">{{
        `Alias ${errors.alias[0]}`
      }}</span>
    </div>
    <div v-show="isBank" class="form-group">
      <label for="destination_account_name" class="edit-profile_form_label">
        {{ $t("add_contact_page.form.account_name") }}
      </label>
      <input v-model="form.recipient_first_name" type="text" class="arcadi-input mt-2"
        :class="{ 'mb-4 arcadi-form__input ': !errors.recipient_first_name }"
        id="destination_account_recipient_first_name" name="destination_account[recipient_first_name]"
        :placeholder="$t('add_contact_page.form.account_name')" :disabled="!updatability" />
      <span class="error-destination" v-if="errors.recipient_first_name">{{
        `${$t("add_contact_page.form.account_name")} ${errors.recipient_first_name[0]
        }`
      }}</span>
    </div>
    <div v-show="isBank && updatability" class="bank-static-message">
      <div class="arcadi-disclaimer mb-8 bank-static-message">
        <img :src="images.arcadiAlert" class="w-8 mr-2 inline" />
        <span v-html="$t('add_contact_page.form.account_name_alert')"></span>
      </div>
    </div>
    <div v-show="isBank" class="form-group">
      <label for="destination_account_number" class="edit-profile_form_label">
        {{ $t("add_contact_page.form.account_last_name") }}
      </label>
      <input v-model="form.recipient_last_name" type="text" class="arcadi-input mt-2"
        :class="{ 'mb-4 arcadi-form__input ': !errors.recipient_last_name }"
        id="destination_account_recipient_last_name" name="destination_account[recipient_last_name]"
        :placeholder="$t('add_contact_page.form.account_last_name')" :disabled="!updatability" />
      <span class="error-destination" v-if="errors.recipient_last_name">{{
        `${$t("add_contact_page.form.account_last_name")} ${errors.recipient_last_name[0]
        }`
      }}</span>
    </div>
    <div v-show="isBank && updatability" class="bank-static-message">
      <div class="arcadi-disclaimer mb-8 bank-static-message">
        <img :src="images.arcadiAlert" class="w-8 mr-2 inline" />
        <span v-html="$t('add_contact_page.form.account_last_name_alert')"></span>
      </div>
    </div>
    <label v-show="isBank" for="destination_account_number" class="edit-profile_form_label">
      {{ $t("add_contact_page.form.identification_type.title") }}
    </label>
    <div v-show="isBank" class="form-group arcadi-select">
      <select v-model="form.recipient_id_type" class="arcadi-input screen-select:appearance-none mt-2"
        :class="{ 'mb-4 arcadi-form__input ': !errors.recipient_id_type }" id="destination_account_recipient_id_type"
        name="destination_account[recipient_id_type]" :disabled="!updatability">
        <option value="">{{ $t('add_contact_page.form.select_option') }}</option>
        <option v-for="(identityType, key) in identityTypes" :key="key" :value="identityType[1]">
          {{ translateIdentity(identityType[0]) }}
        </option>
      </select>
      <span class="error-destination" v-if="errors.recipient_id_type">{{
        `${$t("add_contact_page.form.identification_type.title")} ${errors.recipient_id_type[0]
        }`
      }}</span>
    </div>
    <div v-show="isBank" class="form-group">
      <label for="destination_account_number" class="edit-profile_form_label">
        {{ $t("add_contact_page.form.identification_number") }}
      </label>
      <input v-model="form.recipient_id" type="text" class="arcadi-input mt-2"
        :class="{ 'mb-4 arcadi-form__input': !errors.recipient_id }" id="destination_account_recipient_id"
        name="destination_account[recipient_id]" :placeholder="$t('add_contact_page.form.identification_number')"
        :disabled="!updatability" />
      <span class="error-destination" v-if="errors.recipient_id">{{
        `${$t("add_contact_page.form.identification_number")} ${errors.recipient_id[0]
        }`
      }}</span>
    </div>
    <div v-show="isBank && canUpdate" class="bank-static-message">
      <div class="arcadi-disclaimer mb-8 bank-static-message">
        <img :src="images.arcadiAlert" class="w-8 mr-2 inline" />
        <span v-html="$t('add_contact_page.form.identification_number_alert')"></span>
      </div>
    </div>
    <label for="destination_account_number" class="edit-profile_form_label">
      {{ countryText }}
    </label>
    <div class="form-group arcadi-select">
      <select v-model="form.country_id" class="arcadi-input screen-select:appearance-none mt-2"
        :class="{ 'mb-4 arcadi-form__input': !errors.country_id }" id="destination_account_country_id"
        name="destination_account[country_id]" :disabled="!updatability" @change="getBankByCountry">
        <option value="0">{{ $t('add_contact_page.form.select_option') }}</option>
        <option v-for="(country, key) in countries" :key="key" :value="country.id">
          {{ country.name }}
        </option>
      </select>
      <span class="error-destination" v-if="errors.country_id">{{
        `${countryText} ${errors.country_id[0]}`
      }}</span>
    </div>
    <div v-show="isBank" class="form-group">
      <label for="destination_account_number" class="edit-profile_form_label">
        {{ $t("add_contact_page.form.city_text") }}
      </label>
      <input v-model="form.recipient_city" type="text" class="arcadi-input mt-2"
        :class="{ 'mb-4 arcadi-form__input': !errors.recipient_city }" id="destination_account_recipient_city"
        name="destination_account[recipient_city]" :placeholder="$t('add_contact_page.form.city_text')"
        :disabled="!updatability" />
      <span class="error-destination" v-if="errors.recipient_city">{{
        `${$t("add_contact_page.form.city_text")} ${errors.recipient_city[0]}`
      }}</span>
    </div>
    <div v-show="!hiddenOptionals && isBank && (newUser || (canUpdate && form.recipient_state))" class="form-group">
      <label for="destination_account_number" class="edit-profile_form_label">
        {{ $t("add_contact_page.form.state_text") }}
      </label>
      <input v-model="form.recipient_state" type="text" class="arcadi-input mt-2"
        :class="{ 'mb-4 arcadi-form__input': !errors.recipient_state }" id="destination_account_recipient_state"
        name="destination_account[recipient_state]" :placeholder="$t('add_contact_page.form.state_text')"
        :disabled="!updatability" />
      <span class="error-destination" v-if="errors.recipient_state">{{
        `${$t("add_contact_page.form.state_text")} ${errors.recipient_state[0]}`
      }}</span>
    </div>
    <div v-show="isBank" class="form-group">
      <label for="destination_account_number" class="edit-profile_form_label">
        {{ $t("add_contact_page.form.address_text") }}
      </label>
      <input v-model="form.recipient_home_address" type="text" class="arcadi-input mt-2"
        :class="{ 'mb-4 arcadi-form__input': !errors.recipient_home_address }"
        id="destination_account_recipient_home_address" name="destination_account[recipient_home_address]"
        :placeholder="$t('add_contact_page.form.address_text')" :disabled="!updatability" />
      <span class="error-destination" v-if="errors.recipient_home_address">{{
        `${$t("add_contact_page.form.address_text")} ${errors.recipient_home_address[0]
        }`
      }}</span>
    </div>
    <div v-show="!hiddenOptionals && isBank && (newUser || (canUpdate && form.recipient_phone))" class="form-group">
      <label for="destination_account_number" class="edit-profile_form_label">
        {{ $t("add_contact_page.form.phone_text") }}
      </label>
      <input v-model="form.recipient_phone" type="text" class="arcadi-input mt-2"
        :class="{ 'mb-4 arcadi-form__input ': !errors.recipient_phone }" id="destination_account_recipient_phone"
        name="destination_account[recipient_phone]" :placeholder="$t('add_contact_page.form.phone_text')"
        :disabled="!updatability" />
      <span class="error-destination" v-if="errors.recipient_phone">{{
        `${$t("add_contact_page.form.phone_text")} ${errors.recipient_phone[0]}`
      }}</span>
    </div>
    <div v-show="!hiddenOptionals && isBank && (newUser || (canUpdate && form.recipient_birthdate))" class="form-group">
      <label for="destination_account_recipient_birthdate" class="edit-profile_form_label">
        {{ $t("add_contact_page.form.birthdate_text") }}
      </label>
      <input v-model="form.recipient_birthdate" type="text"
        class="arcadi-input arcadi-input--destination-account-date flatpickr-input mt-2"
        :class="{ 'mb-4 arcadi-form__input': !errors.recipient_birthdate }" id="destination_account_recipient_birthdate"
        name="destination_account[recipient_birthdate]" :placeholder="$t('add_contact_page.form.birthdate_text')"
        :disabled="!updatability" />
      <span class="error-destination" v-if="errors.recipient_birthdate">{{
        `${$t("add_contact_page.form.birthdate_text")} ${errors.recipient_birthdate[0]
        }`
      }}</span>
    </div>
    <label for="destination_account_number" class="edit-profile_form_label">
      {{ destinationAccountText }}
    </label>
    <div class="form-group arcadi-select">
      <select v-model="form.destination_account_bank_id" class="arcadi-input screen-select:appearance-none mt-2" :class="{
        'mb-4 arcadi-form__input': !errors.destination_account_bank_id
      }" id="destination_account_destination_account_bank_id" name="destination_account[destination_account_bank_id]"
        :disabled="!updatability">
        <option value="0">{{ $t('add_contact_page.form.select_option') }}</option>
        <option v-for="(bank, key) in banks" :key="key" :value="bank.id">
          {{ bank.name }}
        </option>
      </select>
      <span class="error-destination" v-if="errors.destination_account_bank_id">{{
        `${destinationAccountText} ${errors.destination_account_bank_id[0]}`
      }}</span>
    </div>
    <div v-show="destinationAccountWarning" class="bank-static-message">
      <div class="arcadi-disclaimer mb-8 bank-static-message">
        <img :src="images.arcadiAlert" class="w-8 mr-2 inline" />
        <span v-html="destinationAccountWarning"></span>
      </div>
    </div>
    <div v-show="isUnitedStates && isBank && (newUser || canUpdate)
        " class="form-group">
      <label for="destination_account_number" class="edit-profile_form_label">
        {{ $t("add_contact_page.form.number_aba") }}
      </label>
      <input v-model="form.aba_number" type="text" class="arcadi-input mt-2"
        :class="{ 'mb-4 arcadi-form__input ': !errors.aba_number }" id="destination_account_aba_number"
        name="destination_account[aba_number]" :placeholder="$t('add_contact_page.form.number_aba')"
        :disabled="!updatability" />
      <span class="error-destination" v-if="errors.aba_number">{{
        `${$t("add_contact_page.form.number_aba")} ${errors.aba_number[0]}`
      }}</span>
    </div>
    <div v-show="isUnitedStates && isBank && (newUser || canUpdate)
        " class="bank-static-message">
      <div class="arcadi-disclaimer mb-8 bank-static-message">
        <img :src="images.arcadiAlert" class="w-8 mr-2 inline" />
        <span v-html="$t('add_contact_page.form.aba_warning')"></span>
      </div>
    </div>
    <div v-show="isSpain && isBank && (newUser || canUpdate)" class="form-group">
      <label for="destination_account_iban_number" class="edit-profile_form_label">
        {{ $t("add_contact_page.form.number_iban") }}
      </label>
      <input v-model="form.iban_number" type="text" class="arcadi-input mt-2"
        :class="{ 'mb-4 arcadi-form__input ': !errors.iban_number }" id="destination_account_iban_number"
        name="destination_account[iban_number]" :placeholder="$t('add_contact_page.form.number_iban')"
        :disabled="!updatability" />
      <span class="error-destination" v-if="errors.iban_number">{{
        `${$t("add_contact_page.form.number_iban")} ${errors.iban_number[0]}`
      }}</span>
    </div>
    <div v-show="isBrasil && isBank && (newUser || canUpdate)" class="form-group">
      <label for="destination_account_cpf_number" class="edit-profile_form_label">
        {{ $t("add_contact_page.form.number_cpf") }}
      </label>
      <input v-model="form.cpf_number" type="text" class="arcadi-input mt-2"
        :class="{ 'mb-4 arcadi-form__input ': !errors.cpf_number }" id="destination_account_cpf_number"
        name="destination_account[cpf_number]" :placeholder="$t('add_contact_page.form.number_cpf')"
        :disabled="!updatability" />
      <span class="error-destination" v-if="errors.cpf_number">{{
        `${$t("add_contact_page.form.number_cpf")} ${errors.cpf_number[0]}`
      }}</span>
    </div>
    <label v-show="isBank" for="destination_account_account_type" class="edit-profile_form_label">
      {{ $t("add_contact_page.form.account_type") }}
    </label>
    <div v-show="isBank" class="form-group arcadi-select">
      <select v-model="form.account_type" class="arcadi-input screen-select:appearance-none mt-2"
        :class="{ 'mb-4 arcadi-form__input': !errors.account_type }" id="destination_account_account_type"
        name="destination_account[account_type]" :disabled="!updatability">
        <option value="0">{{ $t('add_contact_page.form.select_option') }}</option>
        <option v-for="(accountType, key) in accountTypes" :key="key" :value="accountType[1]">
          {{ accountType[0] }}
        </option>
      </select>
      <span class="error-destination" v-if="errors.account_type">{{
        `${$t("add_contact_page.form.account_type")} ${errors.account_type[0]}`
      }}</span>
    </div>
    <div v-show="isBank" class="form-group">
      <label for="destination_account_number" class="edit-profile_form_label">
        {{ $t("add_contact_page.form.account_number") }}
      </label>
      <input v-model="form.number" type="text" class="arcadi-input mt-2"
        :class="{ 'mb-4 arcadi-form__input': !errors.number }" id="destination_account_number"
        name="destination_account[number]" :placeholder="$t('add_contact_page.form.account_number')"
        :disabled="!updatability" />
      <span class="error-destination" v-if="errors.number">{{
        `${$t("add_contact_page.form.account_number")} ${errors.number[0]}`
      }}</span>
    </div>
    <div v-show="isBank && isPeru" class="bank-static-message">
      <div class="arcadi-disclaimer mb-8 bank-static-message">
        <img :src="images.arcadiAlert" class="w-8 mr-2 inline" />
        <span v-html="$t('add_contact_page.form.peru_warning')"></span>
      </div>
    </div>
    <div v-show="!hiddenOptionals && isBank && (newUser || (canUpdate && form.recipient_email))" class="form-group">
      <label for="destination_account_number" class="edit-profile_form_label">
        {{ $t("add_contact_page.form.email_destination") }}
      </label>
      <input v-model="form.recipient_email" type="text" class="arcadi-input mt-2"
        :class="{ 'mb-4 arcadi-form__input': !errors.recipient_email }" id="destination_account_recipient_email"
        name="destination_account[recipient_email]" :placeholder="$t('add_contact_page.form.email_destination')"
        :disabled="!updatability" />
      <span class="error-destination" v-if="errors.recipient_email">{{
        `${$t("add_contact_page.form.email_destination")} ${errors.recipient_email[0]
        }`
      }}</span>
    </div>
    <div v-show="newUser || canUpdate || form.additional_data" class="form-group">
      <label for="destination_account_number" class="edit-profile_form_label">
        {{ additionalDataText }}
      </label>
      <textarea v-model="form.additional_data" type="text" class="arcadi-input mt-2"
        :class="{ 'mb-4 arcadi-form__input': !errors.additional_data }" id="destination_account_additional_data"
        name="destination_account[additional_data]" :placeholder="additionalDataText" :disabled="!updatability">
      </textarea>
      <span class="error-destination" v-if="errors.additional_data">{{
        `${additionalDataText} ${errors.additional_data[0]}`
      }}</span>
    </div>
    <div v-show="additionalDataWarning" class="bank-static-message">
      <div class="arcadi-disclaimer mb-8 bank-static-message">
        <img :src="images.arcadiAlert" class="w-8 mr-2 inline" />
        <span v-html="additionalDataWarning"></span>
      </div>
    </div>
    <input type="hidden" name="destination_account[personal_account]" :value="personalAccount"
      v-show="personalAccount" />
    <div v-show="canUpdate">
      <label for="info_checked" class="flex edit-profile_form_label">
        <input type="checkbox" id="info_checked" name="info_checked" class="mx-1" value="bank" v-model="infoChecked" />
        {{ $t("add_contact_page.form.confirm_button") }}
      </label>
    </div>
  </div>
</template>

<script>
import FormApi from "../../../api/form";
import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es";

export default {
  props: {
    destinationAccount: {
      type: Object,
      required: false,
      default: () => ({})
    },
    identityTypes: {
      type: Array,
      required: true
    },
    accountTypes: {
      type: Array,
      required: true
    },
    countriesCodes: {
      type: Object,
      required: true
    },
    accountCountryCode: {
      type: Number,
      required: false,
      default: 0
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({})
    },
    updatability: {
      type: Boolean,
      required: false,
      default: true
    },
    personalAccount: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      countries: [],
      infoChecked: false,
      banks: [],
      newUser: true,
      hiddenOptionals: true,
      images: {
        arcadiAlert: require("../../../../assets/images/arcadi-alert.svg")
      },
      form: {}
    };
  },
  computed: {
    aliasLabel() {
      return this.isBank
        ? this.$t("add_contact_page.form.alias")
        : this.$t("add_contact_page.form.wallet_contact");
    },
    additionalDataText() {
      return this.isBank
        ? this.$t("add_contact_page.form.additional_data")
        : this.$t("add_contact_page.form.wallet_address");
    },
    countryText() {
      return this.isBank
        ? this.$t("add_contact_page.form.country_text")
        : this.$t("add_contact_page.form.asset");
    },
    destinationAccountText() {
      return this.isBank
        ? this.$t("add_contact_page.form.choose_bank")
        : this.$t("add_contact_page.form.wallet_type");
    },
    instructionsText() {
      return this.isBank
        ? this.$t("add_contact_page.instructions")
        : this.$t("add_contact_page.instructions_2");
    },
    isBank() {
      return this.form.category === "bank";
    },
    canUpdate() {
      return !isNaN(this.updatability) && this.updatability;
    },
    isBrasil() {
      return this.form.country_id === 5;
    },
    isSpain() {
      return this.form.country_id === 6;
    },
    isUnitedStates() {
      return this.form.country_id === 7;
    },
    isPeru() {
      return this.form.country_id === 8;
    },
    destinationAccountWarning() {
      const warningWallet = {
        'ERC20': {
          'USDT': this.$t("add_contact_page.form.tether_erc20_warning"),
          'USDC': this.$t("add_contact_page.form.usdc_erc20_warning")
        },
        'TRC20': {
          'USDT': this.$t("add_contact_page.form.tether_tcr20_warning"),
          'USDC': this.$t("add_contact_page.form.usdc_tcr20_warning")
        }
      };
      const venezuelaBanks = [24, 17, 21, 19, 1, 0];

      const bank = this.banks.find(bank => bank.id === this.form.destination_account_bank_id);
      const country = this.countries.find(country => country.id === this.form.country_id);

      if (bank && country && warningWallet[bank.code] && warningWallet[bank.code][country.code])
        return warningWallet[bank.code][country.code];
      if (this.form.country_id === 1 && !venezuelaBanks.includes(this.form.destination_account_bank_id))
        return this.$t("add_contact_page.form.venezuela_warning");
      return "";
    },
    additionalDataWarning() {
      const venezuelaBanks = [24, 17, 21, 19, 1, 0];
      const warningAdditional = {
        8: this.$t("add_contact_page.form.additional_peru"),
        6: this.$t("add_contact_page.form.additional_spain"),
        1: this.$t("add_contact_page.form.additional_venezuela"),
        5: this.$t("add_contact_page.form.additional_brasil")
      };
      if (warningAdditional[this.form.country_id] && !venezuelaBanks.includes(this.form.destination_account_bank_id))
        return warningAdditional[this.form.country_id];
      return "";
    }
  },
  mounted() {
    this.initComponent("bank");
    const dateSelector = document.querySelector(
      ".arcadi-input--destination-account-date"
    );
    if (dateSelector) {
      const datePicker = flatpickr(".arcadi-input--destination-account-date", {
        locale: Spanish,
        disableMobile: "true",
        altFormat: "d \\de F \\de Y",
        altInput: true,
        dateFormat: "Y-m-d",
        maxDate: new Date()
      });
      const birthday = this.destinationAccount.recipient_birthdate;
      if (birthday) datePicker.setDate(birthday.replace(/"/g, ""));
    }
  },
  methods: {
    getBankByCountry() {
      this.form = { ...this.form };
      FormApi.getBanksByCountry(this.form.country_id).then(response => {
        this.banks = response.data;
      });
    },
    initComponent(category) {
      const form = {
        alias: "",
        recipient_first_name: "",
        recipient_last_name: "",
        recipient_id_type: "",
        recipient_id: "",
        category: category,
        country_id: 0,
        aba_number: "",
        iban_number: "",
        cpf_number: "",
        recipient_city: "",
        recipient_state: "",
        recipient_home_address: "",
        recipient_phone: "",
        recipient_birthdate: "",
        destination_account_bank_id: 0,
        account_type: 0,
        recipient_email: "",
        additional_data: "",
        number: "",
        personal_account: false
      };

      this.banks = [];
      this.form = form;
      if (this.destinationAccount.id) this.newUser = false;
      if (this.destinationAccount.user_id || this.destinationAccount.personal_account) {
        if (this.destinationAccount.category === category) {
          this.form = { ...this.destinationAccount }
          this.form.country_id = this.accountCountryCode;
          this.form.destination_account_bank_id = this.destinationAccount.destination_account_bank_id || 0;
          this.form.account_type = 0
        } else {
          this.form.alias = this.destinationAccount.alias
        }
      }
      FormApi.getAllCountries(this.isBank).then(response => {
        this.countries = response.data;
      });
      this.getBankByCountry();
    },
    translateIdentity(identity) {
      const identityOptions = {
        "Cédula de identidad": this.$t("add_contact_page.form.identification_type.identity_card"),
        "Cédula de extranjero": this.$t("add_contact_page.form.identification_type.foreigner_card"),
        "Cédula de empresa": this.$t("add_contact_page.form.identification_type.passport"),
        "Pasaporte": this.$t("add_contact_page.form.identification_type.company_card"),
      }
      return identityOptions[identity];
    }
  },
  watch: {
    isBank(newValue) {
      let category = newValue ? "bank" : "wallet"
      this.initComponent(category);
    },
    infoChecked() {
      const saveButton = document.querySelector(
        "#save-button-destination_account"
      );
      if (saveButton) {
        saveButton.disabled = !this.infoChecked;
      }
    }
  }
};
</script>

<style scoped>
.error-destination {
  color: #e1374b;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  font-size: 0.75rem;
}

.arcadi-input:disabled {
  background-color: #fff;
  color: #7c7d7f;
  cursor: not-allowed;
}
</style>
