<template>
    <div class="flex flex-col">
      <p>
        Transfiérenos desde tu propia cuenta bancaria.
      </p>
      <br>
      <p>Para que podamos asociar tu pago a tu perfil en Arcadi,
        transfiere un monto que termine en el último dígito de tu Nº de identificación registrado en Arcadi.
      </p>
      <div class="flex mt-2">
      <a @click="showModalTransfer()" style="color: #007bbc;">Ver ejemplo</a>
        <span class="pl-1">
          <button type="button" id="show-modal" @click="showModalTransfer()">
            <img :src="questionMarkSVG" class="inline align-middle" style="min-width: 20px; max-width: 20px;"/>
          </button>
        </span>
      </div>
      <!-- use the modal component, pass in the prop -->
      <modal :show="showModal" @close="showModal = false" :numeroDocumento="idDocumentNumber">
        <template #header>

        </template>
      </modal>
    </div>
</template>

<script>
import Modal from './Modal.vue';

export default {
  beforeMount() {
  },
  components: {
    Modal
  },
  props:{
    idDocumentNumber: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      showModal: false,
      questionMarkSVG: require('../../../../assets/images/question-mark.svg')
    }
  },
  computed: {
  },
  methods: {
    showModalTransfer(){
      this.showModal = true;
    }
  },
};
</script>
