<template>
  <div>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />
    <button type="button" v-on:click="showPassword"><i class="fa-solid fa-eye" style="color: grey;" v-if="!isHidden"></i></button>
    <button type="button" v-on:click="hidePassword"><i class="fa-solid fa-eye-slash" style="color: grey;" v-if="isHidden"></i></button>
  </div>
</template>

<script>
export default {
  props: {
    idinput: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isHidden: false,
    };
  },
  methods: {
     showPassword() {
      const elemento = document.getElementById(this.idinput);
      elemento.type = 'text';
      this.isHidden = true;
     },
     hidePassword() {
      const elemento = document.getElementById(this.idinput);
      elemento.type = 'password';
      this.isHidden = false;
     },
  },
}
</script>

<style>
.vue-country-select {
  height: 45px;
}
</style>
