import api from './index';

export default {
  getCountries(sourceCountriesCode = []) {
    return api({
      method: 'get',
      url: '/api/v2/countries',
      params: {
       'q[code_in]': sourceCountriesCode,
      },
    });
  },
  getExchangeRates(source_country_id, destination_country_id) {
    return api({
      method: 'get',
      url: '/api/v2/exchange_rates',
      params: {
        source_country_id,
        destination_country_id,
      }
    });
  },
  getTransferLimits() {
    return api({
      method: 'get',
      url: '/api/v2/transfer_limits',
    })
  },
  getCountryAssetRates() {
    return api({
      method: 'get',
      url: '/api/v2/country_asset_rates'
    })
  },
  getTransferLimitsWithCountries() {
    return api({
      method: 'get',
      url: '/api/v2/transfer_limits_with_countries',
    })
  },
  getAllCountries() {
    return api({
      method: 'get',
      url: '/api/v2/all_countries',
    });
  },
};
