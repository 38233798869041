<script>
export default {
  props: {
    show: Boolean,
    idCountry: Number,
    countryPath: String
  },
  data() {
    return {
      countries: [
        {
          id: 1, title: 'Venezuela',
          banks: [{id: 1, name: 'Banesco'}, {id: 2, name: 'Provincial'}, {id: 3, name: 'Mercantil'}],
          observation: 'Puedes enviar dinero a otros bancos, pero debes ingresar los datos Pago móvil al agregar la cuenta de destino en tu perfil web, en el campo llamado “Datos adicionales”, de lo contrario el dinero llegará el día siguiente hábil.'
        },
        {
          id: 3, title: 'Ecuador',
          banks: [{id: 1, name: 'Pichincha'}, {id: 2, name: 'Produbanco'}, {id: 3, name: 'Guayaquil'}],
          observation: 'Al agregar la cuenta de destino en tu perfil web, en el campo llamado “Nº de identificación” debes ingresar el que está asociado a la cuenta bancaria, normalmente, corresponde al Nº del documento de identidad con el cual el destinatario abrió su cuenta bancaria.'
        },
        {
          id: 5, title: 'Brasil',
          banks: [{id: 1, name: 'Caixa'}, {id: 2, name: 'Bradesco'}, {id: 3, name: 'Itaú'}],
          observation: 'Al agregar la cuenta de destino en tu perfil web, en el campo llamado “Datos adicionales” debes ingresar el PIX más el número de agencia. El destinatario puede tener como PIX, su número de teléfono, número de CPF o dirección de correo electrónico, entre otros. El PIX permite que los fondos lleguen a destino de forma inmediata.'
        },
        {
          id: 6, title: 'España',
          banks: [{id: 1, name: 'Caixa'}, {id: 2, name: 'Bankia'}],
          observation: 'Puedes enviar dinero a otros bancos, pero el destinatario debe usar Bizum. Si es así, debes ingresar el número celular que el destinario tenga asociado a Bizum en el campo llamado “Datos adicionales” al agregar la cuenta de destino en tu perfil web.'
        },
        {
          id: 7, title: 'EE. UU',
          banks: [{id: 1, name: 'Bank of America (Bofa)'}, {id: 2, name: 'Wells Fargo'}],
          observation: 'Al agregar la cuenta de destino debes ingresar el  routing number en el campo llamado “Número ABA”. El routing number o número de enrutamiento es de 9 dígitos y se usa en conjunto con el número de cuenta bancaria para realizar transferencias bancarias.'
        },
        {
          id: 8, title: 'Perú',
          banks: [{id: 1, name: 'BCP'}, {id: 2, name: 'Scotiabank'}, {id: 3, name: 'Interbank'}, {id: 4, name: 'BBVA'}],
          observation: 'Al agregar la cuenta de destino en tu perfil web, debes ingresar el Nº de cuenta simple en el campo llamado “Número de cuenta”, y en el campo llamado “Datos adicionales”, debes ingresar el CCI (Código de Cuenta Interbancario) de la cuenta bancaria el cual es de 20 dígitos.'
        },
        {
          id: 9, title: 'Chile',
          banks: [{id: 1, name: 'Todos los bancos.'}],
          observation: 'Al agregar la cuenta de destino en tu perfil web, debes ingresar el RUT del destinatario en el campo llamado “Número de identificación”.'
        },
        {
          id: 10, title: 'Cripto wallets',
          banks: [{id: 1, name: 'TRC20'}, {id: 2, name: 'ERC20'}],
          observation: 'Las transferencias de dólares tether (USDT) a billeteras ERC20 tienen una comisión de hasta 30 USDT, mientras que a billeteras TRC20 la comisión es de 2,5 USDT, las cuales son cobradas por la RED y no por Arcadi. Para que la comisión sea de $0 USDT, la billetera de destino debe ser de Binance, en cuyo caso lo mínimo que puedes enviar a una billetera TRC20 es 10 USDT, y a una billetera ERC20, 50 USDT.'
        },
        {
          id: 11, title: 'Cripto wallets',
          banks: [{id: 1, name: 'TRC20'}, {id: 2, name: 'ERC20'}],
          observation: 'Las transferencias de USD Coin (USDC) a billeteras ERC20 tienen una comisión de hasta 30 USDC, mientras que a billeteras TRC20 la comisión es de 2,5 USDC, las cuales son cobradas por la RED y no por Arcadi. Para que la comisión sea de $0 USDC, la billetera de destino debe ser de Binance, en cuyo caso lo mínimo que puedes enviar a una billetera TRC20 es 10 USDC, y a una billetera ERC20, 50 USDC.'
        },
      ]
    }
  },
  methods: {
    getTitle(countryId){
      const country = this.countries.filter((item) => {
        return (item.id == countryId)
      })
      return country.length > 0 ? country[0].title : "";
    },
    getBanks(countryId){
      const country = this.countries.filter((item) => {
        return (item.id == countryId)
      })
      return country.length > 0 ? country[0].banks : [];
    },
    getObservation(countryId){
      const country = this.countries.filter((item) => {
        return (item.id == countryId)
      })
      return country.length > 0 ? country[0].observation : "";
    },
  },
}
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-close">
            <slot name="close-button">
              <button
                class="modal-default-button"
                @click="$emit('close')"
              >X</button>
            </slot>
          </div>
          <div class="modal-header">
            <h3>Destino:</h3>
            <slot name="body">{{ getTitle(idCountry) }}</slot>
            <img :src="countryPath" class="inline align-middle" style="min-width: 28px; max-width: 28px;" />
          </div>

          <div class="modal-body modal-header">
            <h3>Bancos disponibles:</h3>
            <li v-for="(bank) of getBanks(idCountry)" :key="bank.id">
                {{ bank.name }}
            </li>
          </div>

          <div class="modal-body modal-header">
            <p><span>Observaciones:</span>
            {{ getObservation(idCountry) }}</p>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  font-weight: bold;
}

.modal-body {
  margin: 20px 0;
}

.modal-body p{
  font-size: small;
}

.modal-body p span{
  font-weight: bold;
}

.modal-default-button {
  float: right;
  font-size: larger;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

  .close {
    border: none;
    font-size: 30px;
    margin-left: 100px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }
</style>