const readySharedModals = () => {
  const modals = document.querySelectorAll('[data-shared-modal]')

  modals.forEach((modal) => {
    modal.addEventListener('click', () => {
      document.querySelector(modal.dataset.sharedModal).style.display = 'flex'
    })
  })
}

export default readySharedModals
