<template>
  <div id="transfer-sim-vue" class="arcadi-card">
    <div v-if="!loading && Object.values(sourceCountries).length === 0" class="mb-4 arcadi-alert--error">
      {{ $t("simulator_page.no_exchange_rate") }}
    </div>
    <div class="flex">
      <div class="arcadi-card__title flex">
        {{ $t("simulator_page.title") }}
        <img :src="images.cotizaEnvio" class="w-10 ml-4" />
      </div>
    </div>
    <div class="arcadi-card__lead">
      {{ $t("simulator_page.instructions") }}
    </div>
    <div class="flex mt-5 ml-4 mb-2">
      <span class="font-color-primary">{{ $t("simulator_page.you_pay") }}</span>
    </div>
    <div class="flex flex-col transfer-amount-container-body" :class="[{ 'mb-3': !errorAmount() }]">
      <div class="flex">
        <v-select v-model="selectedSourceCountry" :options="sourceCountriesOrder.map(countryId => sourceCountries[countryId])
      " appendToBody label="name" class="flex items-center" :clearable="false" :searchable="false"
          :noDrop="Object.keys(sourceCountries).length < 2">
          <template v-slot:[slotName]="country" v-for="slotName in ['option', 'selected-option']">
            <span class="overflow-visible whitespace-no-wrap" :key="slotName">
              <img :src="country.urlPath" class="inline align-middle flag-style"
                style="min-width: 34px; max-width: 34px" />
            </span>
          </template>
          <template #no-options> No hay tasas disponibles </template>
        </v-select>
        <div class="w-full">
          <input type="tel" inputmode="numeric" :rawvalue="sourceAmount" autocomplete="off"
            @input="handleSourceAmountInput" v-numeric="sourceAmountMask"
            class="w-full arcadi-input shadow-none border-gray-custom rounded-right bg-transparent"
            style="height: 77px; font-weight: 500; font-size: 24px" />
        </div>
      </div>
    </div>
    <span v-if="errorAmount()" class="ml-4 mb-2 text-xs text-red-400">{{
      errorAmount()
    }}</span>
    <div v-if="selectedSourceCurrency" class="flex mb-4 ml-4 text-sm font-color-primary font-medium">
      <span class="font-semibold">{{ $t("simulator_page.actual_money") }}:</span>
      <format-amount :amount="selectedBalance && Number(selectedBalance.balance) ? -selectedBalance.balance : 0"
        :currency="selectedSourceCurrency" :maximumFractionDigits="selectedSourceCountry.currency === 'CLP' ? 0 : 2" />
    </div>
    <div class="flex mb-3 ml-4">
      <span class="font-color-primary">{{
      $t("simulator_page.recipient_gets")
    }}</span>
    </div>
    <div class="flex flex-col mb-3 transfer-amount-container-body transfer-amount-container-body-green">
      <div class="flex mb-4">
        <v-select v-model="selectedDestinationCountry" :options="selectedDestinationCountries" appendToBody
          class="flex items-center" style="min-width: 80px;" label="name" :clearable="false" :searchable="false">
          <template v-slot:[slotName]="country" v-for="slotName in ['option', 'selected-option']">
            <span class="overflow-visible whitespace-no-wrap" :key="slotName">
              <img :src="country.urlPath" class="inline align-middle flag-style"
                style="min-width: 34px; max-width: 34px" />
            </span>
          </template>
          <template v-slot:no-options> No hay tasas disponibles </template>
        </v-select>
        <div class="flex item-center w-full pl-4" style="height: 77px;">
          <format-amount :amount="destinationAmount" :currency="selectedExchangeRateCurrency" :maximumFractionDigits="2"
            :minimumFractionDigits="2" :numberStyle="'font-weight: 500; font-size: 24px'" />
        </div>
        <div v-show="isPeru" id="destination_amount" class="flex items-center justify-end pr-8">
          <v-select v-model="selectedDestinationCurrency" appendToBody :clearable="false" :searchable="false"
            :options="Object.keys(exchangeRateList)" class="w-16 currency-selector"
            v-bind:class="{ 'hide-when-not-peru': !isPeru }">
            <template #selected-option>
              <span></span>
            </template>
            <template #option="option">
              {{ option.label.toUpperCase() }}
            </template>
          </v-select>
        </div>
      </div>
    </div>
    <div class="p-2 p-6 mb-4 text-sm bg-white rounded-lg lg:text-base exchange-container">
      <div id="exchange-rate" class="flex justify-between mb-4">
        <span class="font-normal text-left">
          {{ $t("simulator_page.exchange_rate") }}:
        </span>
        <span v-if="Object.keys(selectedExchangeRate).length === 0" class="text-right whitespace-no-wrap">
          {{ AutoNumeric.format(1, exchangeRateMask(selectedSourceCurrency)) }}
          =
        </span>
        <span v-else-if="Number(selectedExchangeRate.rate) > 1" class="flex text-right whitespace-no-wrap">
          {{ AutoNumeric.format(1, exchangeRateMask(selectedSourceCurrency)) }}
          =
          <format-amount :amount="selectedExchangeRateFeeles || 0" :currency="selectedExchangeRateCurrency"
            :maximumFractionDigits="2" :minimumFractionDigits="2" />
        </span>
        <span v-else class="flex text-right whitespace-no-wrap">
          {{
      AutoNumeric.format(
        1,
        exchangeRateMask(selectedExchangeRateCurrency)
      )
    }}
          =
          <format-amount :amount="1 / (selectedExchangeRateFeeles || 1)" :currency="selectedSourceCurrency"
            :maximumFractionDigits="2" :minimumFractionDigits="2" />
        </span>
      </div>
      <hr class="w-full text-gray-10% mb-2" />
      <div id="total-commision" class="flex justify-between my-4">
        <span class="font-normal text-left">
          {{ $t("simulator_page.total_commission") }}:
        </span>
        <span v-if="selectedSourceCurrency" class="text-right whitespace-no-wrap">
          <format-amount :amount="commission" :currency="selectedSourceCurrency"
            :maximumFractionDigits="selectedSourceCountry.currency === 'CLP' ? 0 : 2"
            :minimumFractionDigits="selectedSourceCountry.currency === 'CLP' ? 0 : 2" />
        </span>
      </div>
      <hr class="w-full text-gray-10% mb-2" />
      <div id="final-amount" class="flex justify-between">
        <span class="font-normal text-left">
          {{ $t("simulator_page.mount_to_send") }}:
        </span>
        <span v-if="selectedSourceCurrency" class="text-right whitespace-no-wrap">
          <format-amount :amount="totalToSend" :currency="selectedSourceCurrency"
            :maximumFractionDigits="selectedSourceCountry.currency === 'CLP' ? 0 : 2"
            :minimumFractionDigits="selectedSourceCountry.currency === 'CLP' ? 0 : 2" />
        </span>
      </div>
    </div>
    <div id="arrive-date" class="flex justify-between mb-4 money-will-arrive-container" style="padding-left: 10px">
      <span class="text-sm lg:text-base font-normal">
        {{ $t("simulator_page.money_will_receive") }}:
        <b>
          {{
      selectedDestinationCountry ? selectedDestinationCountry.eta : "N/A"
    }}
        </b>
      </span>
    </div>
    <div class="mb-4">
      <a :href="buttonUrl" class="text-white shadow-md arcadi-form__submit arcadi-button">
        {{ $t("simulator_page.continue_button") }}
      </a>
    </div>
  </div>
</template>

<style scoped>
#transfer-sim-vue>>>.vs__dropdown-toggle {
  border: none;
  border-radius: 0;
  background: transparent;
}

#transfer-sim-vue .vs__dropdown-toggle,
#transfer-sim-vue .vue__arcadi-input {
  height: 100%;
  background-color: transparent m !important;
  -webkit-border-radius: 0.6rem 0 0 0.6rem;
  -moz-border-radius: 0.6rem 0 0 0.6rem;
  border-radius: 0.6rem 0 0 0.6rem;
}

.simulator-vue {
  max-width: 545px;
  border-radius: 30px;
  font-family: "Poppins", sans-serif;
  color: #262626;
}

.simulator-vue span {
  font-family: "Poppins", sans-serif;
  color: black;
}

.simulator-vue input {
  font-family: "Poppins", sans-serif;
  color: black;
}

.transfer-amount-container-body {
  border-radius: 27px;
  border-left: 6px solid #611de9;
  background: #f9f9f9;
  height: 77px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.transfer-amount-container-body-green {
  border-left: 6px solid #ccff00;
}

@media screen and (max-width: 992px) {
  .simulator-vue {
    max-width: 100%;
  }
}

@media screen and (max-width: 720px) {
  .simulator-vue {
    border-radius: 0px 0px 35px 35px;
  }
}

.currency-selector.v-select,
.currency-selector.v-select .vs__selected-options {
  min-width: 0px;
}

.currency-selector.v-select .vs__dropdown-toggle {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.v-select {
  min-width: 65px;
}

.vs__selected-options {
  min-width: 35px;
  margin-left: 10px;
  margin-right: 10px;
}

.vs--single.vs--open .vs__selected {
  position: relative;
}

.vs--disabled div.vs__dropdown-toggle {
  background-color: transparent !important;
  cursor: not-allowed;
}

.transfer-sim-vue .vs__dropdown-toggle,
.transfer-sim-vue .vue__arcadi-input {
  height: 100%;
  -webkit-border-radius: 0.6rem 0 0 0.6rem;
  -moz-border-radius: 0.6rem 0 0 0.6rem;
  border-radius: 0.6rem 0 0 0.6rem;
}

.vs__dropdown-menu {
  min-width: 0px;
  width: auto;
}

.vs__dropdown-menu li {
  padding: 4px 0 4px 10px;
}

.vs__dropdown-menu span {
  font-family: "Poppins", sans-serif;
}

.vs__open-indicator {
  transform: scale(0.8);
}

.vs__search {
  position: absolute;
}

.vs--unsearchable .vs__search {
  opacity: 0;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}

.exchange-container {
  border-radius: 35px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: #f9f9f9;
}

.rounded-right {
  -webkit-border-radius: 0 0.6rem 0.6rem 0;
  -moz-border-radius: 0 0.6rem 0.6rem 0;
  border-radius: 0 0.6rem 0.6rem 0;
}

#destination_amount>>>.vs__open-indicator {
  fill: white;
}

#destination_amount>>>.vs__actions {
  background: #611de9;
  width: 24px;
  height: 23px;
  border-radius: 50%;
  padding: 0px 4px 0px 4px;
}
</style>

<script>
import { normalize } from "normalizr";
import schema from "../../../schema";
import AutoNumeric from "autonumeric";
import formatAmount from "../amount/format-amount.vue";

import SimulatorApi from "../../../api/simulator";
import { getLastValidExchangeRateV2 } from "../../../utils/exchange_rates";

export default {
  beforeMount() {
    this.getSimulatorData();
    this.getTransferLimits();
  },
  components: {
    formatAmount
  },
  props: {
    sourceCountriesCode: {
      type: Array,
      default: []
    },
    goTo: {
      type: String,
      default: ""
    },
    availableBalance: {
      type: Number,
      required: true
    },
    balanceAccounts: {
      type: Array,
      required: true
    }
  },
  computed: {
    buttonUrl() {
      if (this.goTo && this.goTo !== "") {
        return this.goTo;
      } else {
        return "users/sign_in";
      }
    },
    selectedDestinationCountries() {
      if (Object.values(this.selectedSourceCountry).length > 0) {
        return Object.values(this.selectedSourceCountry.destinationCountries)
          .length > 0
          ? this.selectedSourceCountry.destinationCountries.map(
            id => this.destinationCountries[id]
          )
          : [];
      }

      return [];
    },
    sourceAmountMask() {
      return {
        allowDecimalPadding: "floats",
        modifyValueOnWheel: false,
        decimalPlaces: this.selectedSourceCountry.currency === "CLP" ? 0 : 2,
        decimalPlacesRawValue: null,
        caretPositionOnFocus: "end",
        selectOnFocus: false,
        decimalCharacter: this.$i18n.locale === 'en' ? "." : ",",
        digitGroupSeparator: this.$i18n.locale === 'en' ? "," : ".",
        currencySymbol: ` ${this.selectedSourceCurrency || ""}`,
        currencySymbolPlacement: "s",
        emptyInputBehavior: "always"
      };
    },
    destinationAmountMask() {
      return {
        allowDecimalPadding: "floats",
        decimalPlaces: 2,
        decimalPlacesRawValue: null,
        decimalCharacter: this.$i18n.locale === 'en' ? "." : ",",
        digitGroupSeparator: this.$i18n.locale === 'en' ? "," : ".",
        currencySymbol: ` ${this.selectedExchangeRateCurrency || ""}`,
        currencySymbolPlacement: "s",
        emptyInputBehavior: "always",
        readOnly: true
      };
    },
    selectedSourceCurrency() {
      if (this.selectedSourceCountry.currency === "VES") {
        return "Bs";
      }

      return this.selectedSourceCountry.currency;
    },
    selectedExchangeRateCurrency() {
      if (this.selectedDestinationCurrency.toUpperCase() === "VES") {
        return "Bs";
      }

      return this.selectedDestinationCurrency.toUpperCase();
    },
    selectedExchangeRateFeeles() {
      return (
        Number(this.selectedExchangeRate.rate) / (1 - this.percentageCommission)
      );
    },
    selectedTransferLimit() {
      return (
        this.transferLimits.find(transferLimit => {
          return (
            transferLimit.sourceCountryId == this.selectedSourceCountry.id &&
            transferLimit.destinationCountryId ==
            this.selectedDestinationCountry.id
          );
        }) || {}
      );
    },
    percentageCommission() {
      return this.selectedExchangeRate.fee;
    },
    destinationAmount() {
      if (Object.keys(this.selectedExchangeRate).length === 0) {
        return 0;
      }

      return this.sourceAmount * Number(this.selectedExchangeRate.rate);
    },
    currencyWithMoreBalance() {
      const balanceObject = this.balanceAccounts.reduce((prev, current) =>
        -prev.balance > -current.balance ? prev : current
      );
      return balanceObject.currency;
    },
    commission() {
      return this.destinationAmount > 0
        ? this.sourceAmount -
        this.sourceAmount * (1 - this.percentageCommission)
        : 0;
    },
    totalToSend() {
      return this.destinationAmount > 0
        ? this.sourceAmount * (1 - this.percentageCommission)
        : 0;
    },
    selectedBalance() {
      return this.balanceAccounts.find(
        account => account.currency === this.selectedSourceCurrency
      );
    }
  },
  data() {
    return {
      selectedDestinationCurrency: "",
      sourceCountriesOrder: [],
      sourceCountries: {},
      destinationCountries: {},
      loading: true,
      transferLimits: [],
      selectedSourceCountry: {},
      selectedDestinationCountry: {},
      exchangeRateList: {},
      sourceAmount: 0,
      selectedExchangeRate: {},
      AutoNumeric,
      isPeru: false,
      images: {
        cotizaEnvio: require("../../../../assets/images/Menu-CotizaEnvio.svg")
      }
    };
  },
  methods: {
    exchangeRateMask(currencySymbol) {
      return {
        allowDecimalPadding: "floats",
        decimalPlaces: 2,
        roundingMethod: "S",
        decimalPlacesRawValue: null,
        decimalCharacter: this.$i18n.locale === 'en' ? "." : ",",
        digitGroupSeparator: this.$i18n.locale === 'en' ? "," : ".",
        currencySymbolPlacement: "s",
        emptyInputBehavior: "always",
        readOnly: true,
        currencySymbol: ` ${currencySymbol}`
      };
    },
    getSimulatorData() {
      if (this.availableBalance > 0) {
        this.sourceCountriesCode.push("USDT");
        this.sourceCountriesCode.push("USDC");
      }
      SimulatorApi.getCountries().then(({ data }) => {
        const normalizedData = normalize(data, [schema.sourceCountry]);
        this.sourceCountriesOrder = normalizedData.result;
        this.sourceCountries = normalizedData.entities.sourceCountries;
        this.destinationCountries =
          normalizedData.entities.destinationCountries;
        this.loading = false;
      });
    },
    getTransferLimits() {
      SimulatorApi.getTransferLimits().then(({ data }) => {
        this.transferLimits = data;
      });
    },
    getExchangeRateList() {
      if (!this.selectedSourceCountry || !this.selectedDestinationCountry) {
        return;
      }

      SimulatorApi.getExchangeRates(
        this.selectedSourceCountry.id,
        this.selectedDestinationCountry.id
      ).then(({ data }) => {
        this.exchangeRateList = data;
        this.selectedDestinationCurrency = Object.keys(data)[0];
        this.setExchangeRate();
      });
    },
    restartAmounts() {
      this.sourceAmount = null;
    },
    setExchangeRate() {
      if (Object.keys(this.exchangeRateList).length === 0) {
        return;
      }

      this.selectedExchangeRate =
        getLastValidExchangeRateV2(
          this.exchangeRateList[this.selectedDestinationCurrency],
          this.sourceAmount
        ) || {};
    },
    handleSourceAmountInput(event) {
      this.sourceAmount = Number(event.target.autonumeric.rawValue);
    },
    errorAmount() {
      if (
        this.sourceAmount > 0 &&
        this.sourceAmount < this.selectedTransferLimit.minimumAmount
      ) {
        return `El monto mínimo es ${AutoNumeric.format(
          this.selectedTransferLimit.minimumAmount,
          this.sourceAmountMask
        )}`;
      } else if (this.sourceAmount > this.selectedTransferLimit.maximumAmount) {
        return `El monto máximo es ${AutoNumeric.format(
          this.selectedTransferLimit.maximumAmount,
          this.sourceAmountMask
        )}`;
      }

      return false;
    }
  },
  watch: {
    selectedSourceCountry() {
      this.getSimulatorData();
      this.restartAmounts();
      this.selectedDestinationCountry =
        this.selectedDestinationCountries.find(
          country => country.id == this.selectedDestinationCountry.id
        ) || this.selectedDestinationCountries[0];
    },
    selectedDestinationCountry(val) {
      this.isPeru = val.name == "Perú";
      this.getSimulatorData();
      if (val !== null) {
        this.getExchangeRateList();
      }
    },
    sourceAmount() {
      this.setExchangeRate();
    },
    loading(val) {
      if (val === false) {
        this.selectedSourceCountry =
          this.sourceCountries[this.sourceCountriesOrder[0]] || {};
        const countryWithBalance = this.sourceCountriesOrder.find(
          countryId =>
            this.sourceCountries[countryId].currency === this.currencyWithMoreBalance
        );
        if (countryWithBalance) this.selectedSourceCountry = this.sourceCountries[countryWithBalance];
        this.selectedDestinationCountry =
          this.selectedDestinationCountries[0] || {};
      }
    },
    selectedDestinationCurrency() {
      this.setExchangeRate();
    }
  }
};
</script>
