const AutoNumeric = require("autonumeric")

function defaultMask(input) {
  return {
    allowDecimalPadding: "floats",
    modifyValueOnWheel: false,
    decimalPlaces: input.dataset.currency === "CLP" ? 0 : 2,
    decimalPlacesRawValue: null,
    caretPositionOnFocus: "end",
    selectOnFocus: false,
    decimalCharacter: input.dataset.locale === "en" ? "." : ",",
    digitGroupSeparator: input.dataset.locale === "en" ? "," : ".",
    currencySymbol: ` ${input.dataset.currency}`,
    currencySymbolPlacement: "s",
    emptyInputBehavior: "always"
  }
}

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".money-input").forEach((input) => {
    new AutoNumeric(input, 0, defaultMask(input))

    input.addEventListener("autoNumeric:rawValueModified", (event) => {
      let hiddenInput = document.querySelector(`[name="${input.dataset.name}"]`)
      hiddenInput.value = event.detail.newRawValue
    })
  })
})
