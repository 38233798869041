<template>
  <div class="inline">
    <button type="button" id="show-modal" @click="showModalTransfer()">
      <img :src="questionMarkSVG" class="inline align-middle" style="min-width: 15px; max-width: 15px;"/>
    </button>

    <!-- use the modal component, pass in the prop -->
    <modal :show="showModal" @close="showModal = false" :hash="tatumId">
      <template #header>

      </template>
    </modal>
  </div>
</template>

<script>
import Modal from './Modal.vue';

export default {
  beforeMount() {
  },
  components: {
    Modal
  },
  props:{
    tatumId: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      showModal: false,
      questionMarkSVG: require('../../../../assets/images/question-mark.svg')
    }
  },
  computed: {
  },
  methods: {
    showModalTransfer(){
      this.showModal = true;
    }
  },
};
</script>
