<template>
  <div
    class="w-full mt-8 px-3 pt-8 pb-6 bg-white lg:mt-8 lg:mr-20 lg:ml-1 lg:shadow-md lg:pt-16 lg:pb-16 lg:pl-12 lg:pr-12 simulator-vue"
  >
    <div
      v-if="!loading && Object.values(sourceCountries).length === 0"
      class="mb-4 arcadi-alert--error"
    >
      No hay tasas de cambio válidas en este momento, inténtelo de nuevo más
      adelante!
    </div>
    <div class="flex mb-3">
      <div
        class="w-full font-medium text-center landing__subtitle landing__subtitle--desktop-huge title"
      >
        {{ $t("simulator_page.title") }}
      </div>
    </div>
    <div class="flex mb-2">
      <span class="font-normal subtitle ml-6"
        >{{ $t("simulator_page.you_pay") }}:</span
      >
    </div>
    <div
      class="flex flex-col mb-3 balance-container border-container-balance px-2 py-4"
    >
      <div class="flex">
        <v-select
          v-model="selectedSourceCountry"
          :options="
            sourceCountriesOrder.map(countryId => sourceCountries[countryId])
          "
          appendToBody
          label="name"
          :clearable="false"
          :searchable="false"
          class="border-t-0 md:w-2/5 lg:w-1/2"
          :noDrop="Object.keys(sourceCountries).length < 2"
        >
          <template
            v-slot:[slotName]="country"
            v-for="slotName in ['option', 'selected-option']"
          >
            <span class="overflow-visible whitespace-no-wrap" :key="slotName">
              <img
                :src="country.urlPath"
                class="inline align-middle flag-style"
                style="min-width: 34px; max-width: 34px;"
              />
              <span class="hidden ml-2 align-middle lg:inline">{{
                country.name
              }}</span>
            </span>
          </template>
          <template #no-options>
            {{ $t("simulator_page.no_exchange_rate") }}
          </template>
        </v-select>
        <input
          type="tel"
          inputmode="numeric"
          :rawvalue="sourceAmount"
          autocomplete="off"
          @input="handleSourceAmountInput"
          v-numeric="sourceAmountMask"
          class="arcadi-input w-full px-2 ml-2 text-xl border-b shadow-none appearance-none vue__arcadi-input lg:w-1/2 without-border"
        />
      </div>
      <span v-if="errorAmount()" class="ml-6 text-xs text-red-400">{{
        errorAmount()
      }}</span>
    </div>
    <div class="flex mb-2">
      <span class="font-normal subtitle ml-6"
        >{{ $t("simulator_page.recipient_gets") }} :</span
      >
    </div>
    <div class="flex mb-4 balance-container border-container-receive px-2 py-4">
      <v-select
        v-model="selectedDestinationCountry"
        :options="selectedDestinationCountries"
        appendToBody
        label="name"
        :clearable="false"
        :searchable="false"
        class="md:w-2/5 lg:w-1/2"
      >
        <template
          v-slot:[slotName]="country"
          v-for="slotName in ['option', 'selected-option']"
        >
          <span class="overflow-visible whitespace-no-wrap" :key="slotName">
            <img
              :src="country.urlPath"
              class="inline align-middle flag-style"
              style="min-width: 34px; max-width: 34px;"
            />
            <span class="hidden ml-2 align-middle lg:inline">{{
              country.name
            }}</span>
          </span>
        </template>
        <template v-slot:no-options>
          {{ $t("simulator_page.no_exchange_rate") }}
        </template>
      </v-select>
      <div
        id="destination_amount"
        class="flex justify-between w-full ml-2 lg:w-1/2"
      >
        <format-amount
          :amount="destinationAmount"
          :currency="selectedExchangeRateCurrency"
          :maximumFractionDigits="2"
          class="ml-2 w-1/6 text-xl border-b shadow-none appearance-none vue__arcadi-input without-border focus:outline-none"
        />
        <v-select
          v-model="selectedDestinationCurrency"
          appendToBody
          :clearable="false"
          :searchable="false"
          :options="Object.keys(exchangeRateList)"
          class="w-16 currency-selector"
          v-bind:class="{ 'hide-when-not-peru': !isPeru }"
        >
          <template #selected-option>
            <span></span>
          </template>
          <template #option="option">
            {{ option.label.toUpperCase() }}
          </template>
        </v-select>
      </div>
    </div>
    <div class="p-6 mb-4 text-sm lg:text-base balance-container">
      <div id="exchange-rate" class="flex justify-between mb-4">
        <span class="font-normal text-left">
          {{ $t("simulator_page.exchange_rate") }}:
        </span>
        <span
          v-if="Object.keys(selectedExchangeRate).length === 0"
          class="text-right whitespace-no-wrap"
        >
          {{ AutoNumeric.format(1, exchangeRateMask(selectedSourceCurrency)) }}
          =
        </span>
        <span
          v-else-if="Number(selectedExchangeRate.rate) > 1"
          class="flex text-right whitespace-no-wrap"
        >
          {{ AutoNumeric.format(1, exchangeRateMask(selectedSourceCurrency)) }}
          =
          <format-amount
            :amount="selectedExchangeRateFeeles || 0"
            :currency="selectedExchangeRateCurrency"
            :maximumFractionDigits="2"
          />
        </span>
        <span v-else class="flex text-right whitespace-no-wrap">
          {{
            AutoNumeric.format(
              1,
              exchangeRateMask(selectedExchangeRateCurrency)
            )
          }}
          =
          <format-amount
            :amount="1 / (selectedExchangeRateFeeles || 1)"
            :currency="selectedSourceCurrency"
            :maximumFractionDigits="2"
          />
        </span>
      </div>
      <hr class="w-full text-gray-10% mb-2" />
      <div id="total-commision" class="flex justify-between mb-4">
        <span class="font-normal text-left">
          {{ $t("simulator_page.total_commission") }}:
        </span>
        <span class="text-right whitespace-no-wrap">
          <format-amount
            :amount="commission"
            :currency="selectedSourceCurrency"
            :maximumFractionDigits="
              selectedSourceCountry.currency === 'CLP' ? 0 : 2
            "
          />
        </span>
      </div>
      <hr class="w-full text-gray-10% mb-2" />
      <div id="final-amount" class="flex justify-between">
        <span class="font-normal text-left">
          {{ $t("simulator_page.mount_to_send") }}:
        </span>
        <span class="text-right whitespace-no-wrap">
          <format-amount
            :amount="totalToSend"
            :currency="selectedSourceCurrency"
            :maximumFractionDigits="
              selectedSourceCountry.currency === 'CLP' ? 0 : 2
            "
          />
        </span>
      </div>
    </div>
    <div
      id="arrive-date"
      class="flex justify-between mb-4 green-container px-4 py-1"
    >
      <span class="font-normal center-img text-left" style="color: #611DE9;">
        {{ $t("simulator_page.money_will_receive") }}
        <b>{{
          selectedDestinationCountry ? selectedDestinationCountry.eta : "N/A"
        }}</b>
      </span>
    </div>
    <div class="grid justify-items-center center-img">
      <a
        :href="buttonUrl"
        class="text-white shadow-md arcadi-form__submit dashboard-tab-button-primary w-full mt-0"
        style="height: 50px;"
      >
        {{ $t("simulator_page.continue_transfer") }}
      </a>
    </div>
    <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
    />
    <a :href="buttonWhatsapp" class="float whatsapp-button" target="_blank">
      <i class="fa fa-whatsapp my-float"></i>
    </a>
  </div>
</template>

<style>
.simulator-vue {
  max-width: 545px;
  border: 1px solid rgba(76, 76, 76, 0.3);
  border-radius: 30px;
  color: #262626;
  box-shadow: 2px 2px 4px 0px rgba(90, 93, 95, 0.1);
  background: #f9f9f9;
}

.simulator-vue .title {
  font-weight: 500;
  background: linear-gradient(
    90deg,
    rgba(84, 0, 253, 0.8) 0%,
    rgba(255, 0, 184, 0.8) 56.87%,
    rgba(255, 77, 1, 0.8) 112.61%
  );
  background-clip: text;
  color: transparent;
}

.simulator-vue .subtitle {
  size: 16px;
  font-weight: 400;
  color: #611de9;
}

.balance-container {
  border-radius: 40px;
  border: 1px solid rgba(76, 76, 76, 0.2);
  margin-top: 15x;
  background: #fff;
}

.border-container-balance {
  box-shadow: 6px 0px 0px 0px #611de9 inset;
}

.border-container-receive {
  box-shadow: 6px 0px 0px 0px #ccff00 inset;
}

.without-border {
  border: none;
}

.simulator-vue input {
  font-family: "Poppins", sans-serif;
  color: black;
  border-radius: 35px;
  background-color: #fff;
}

@media screen and (max-width: 992px) {
  .simulator-vue {
    max-width: 100%;
  }
}

.currency-selector.v-select,
.currency-selector.v-select .vs__selected-options {
  min-width: 0px;
}

.currency-selector.v-select .vs__dropdown-toggle {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.simulator-vue .vs__dropdown-toggle {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-radius: 0;
}

.v-select {
  min-width: 65px;
}
.vs__selected-options {
  min-width: 35px;
  margin-left: 10px;
  margin-right: 10px;
}

.vs--single.vs--open .vs__selected {
  position: relative;
}

.simulator-vue .vs__dropdown-toggle,
.simulator-vue .vue__arcadi-input {
  height: 45px;
}
.simulator-vue .vs__dropdown-toggle {
  border: none;
  border-radius: 0;
  background: transparent;
}

.vs__dropdown-menu {
  min-width: 0px;
  width: auto;
}

.vs__dropdown-menu li {
  padding: 4px 0 4px 10px;
}

.vs__dropdown-menu span {
  font-family: "Poppins", sans-serif;
}

.vs__open-indicator {
  transform: scale(0.8);
}

.vs__search {
  position: absolute;
}

.vs--unsearchable .vs__search {
  opacity: 0;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}

.hide-when-not-peru {
  display: none;
}

.width-100-when-not-peru {
  width: 100%;
}
</style>

<script>
import { normalize } from "normalizr";
import schema from "../../../schema";
import AutoNumeric from "autonumeric";

import SimulatorApi from "../../../api/simulator";
import { getLastValidExchangeRateV2 } from "../../../utils/exchange_rates";
import FormatAmount from "../amount/format-amount.vue";

export default {
  beforeMount() {
    this.getSimulatorData();
    this.getTransferLimits();
  },
  components: {
    FormatAmount
  },
  props: {
    sourceCountriesCode: {
      type: Array,
      default: []
    },
    goTo: {
      type: String,
      default: ""
    },
    whatsapp: {
      type: String,
      default: ""
    }
  },
  computed: {
    buttonUrl() {
      if (this.goTo && this.goTo !== "") {
        return this.goTo;
      } else {
        return "users/sign_up";
      }
    },
    buttonWhatsapp() {
      this.whatsapp = this.whatsapp.replace("/", "");
      return "https://api.whatsapp.com/send?phone=" + this.whatsapp;
    },
    selectedDestinationCountries() {
      if (Object.values(this.selectedSourceCountry).length > 0) {
        return Object.values(this.selectedSourceCountry.destinationCountries)
          .length > 0
          ? this.selectedSourceCountry.destinationCountries.map(
              id => this.destinationCountries[id]
            )
          : [];
      }

      return [];
    },
    sourceAmountMask() {
      return {
        allowDecimalPadding: "floats",
        modifyValueOnWheel: false,
        decimalPlaces: this.selectedSourceCountry.currency === "CLP" ? 0 : 2,
        decimalPlacesRawValue: null,
        caretPositionOnFocus: "end",
        selectOnFocus: false,
        decimalCharacter: ",",
        digitGroupSeparator: ".",
        currencySymbol: ` ${this.selectedSourceCurrency || ""}`,
        currencySymbolPlacement: "s",
        emptyInputBehavior: "always"
      };
    },
    destinationAmountMask() {
      return {
        allowDecimalPadding: "floats",
        decimalPlaces: 2,
        decimalPlacesRawValue: null,
        decimalCharacter: ",",
        digitGroupSeparator: ".",
        currencySymbol: ` ${this.selectedExchangeRateCurrency || ""}`,
        currencySymbolPlacement: "s",
        emptyInputBehavior: "always",
        readOnly: true
      };
    },
    selectedSourceCurrency() {
      if (this.selectedSourceCountry.currency === "VES") {
        return "Bs";
      }

      return this.selectedSourceCountry.currency;
    },
    selectedExchangeRateCurrency() {
      if (this.selectedDestinationCurrency.toUpperCase() === "VES") {
        return "Bs";
      }

      return this.selectedDestinationCurrency.toUpperCase();
    },
    selectedExchangeRateFeeles() {
      return (
        Number(this.selectedExchangeRate.rate) / (1 - this.percentageCommission)
      );
    },
    selectedTransferLimit() {
      return (
        this.transferLimits.find(transferLimit => {
          return (
            transferLimit.sourceCountryId == this.selectedSourceCountry.id &&
            transferLimit.destinationCountryId ==
              this.selectedDestinationCountry.id
          );
        }) || {}
      );
    },
    percentageCommission() {
      return this.selectedExchangeRate.fee;
    },
    destinationAmount() {
      if (Object.keys(this.selectedExchangeRate).length === 0) {
        return 0;
      }

      return this.sourceAmount * Number(this.selectedExchangeRate.rate);
    },
    commission() {
      return this.destinationAmount > 0
        ? this.sourceAmount -
            this.sourceAmount * (1 - this.percentageCommission)
        : 0;
    },
    totalToSend() {
      return this.destinationAmount > 0
        ? this.sourceAmount * (1 - this.percentageCommission)
        : 0;
    }
  },
  data() {
    return {
      selectedDestinationCurrency: "",
      sourceCountriesOrder: [],
      sourceCountries: {},
      destinationCountries: {},
      loading: true,
      transferLimits: [],
      selectedSourceCountry: {},
      selectedDestinationCountry: {},
      exchangeRateList: {},
      sourceAmount: 0,
      selectedExchangeRate: {},
      AutoNumeric,
      isPeru: false
    };
  },
  methods: {
    exchangeRateMask(currencySymbol) {
      return {
        allowDecimalPadding: "floats",
        decimalPlaces: 2,
        roundingMethod: "S",
        decimalPlacesRawValue: null,
        decimalCharacter: ",",
        digitGroupSeparator: ".",
        currencySymbolPlacement: "s",
        emptyInputBehavior: "always",
        readOnly: true,
        currencySymbol: ` ${currencySymbol}`
      };
    },
    getSimulatorData() {
      SimulatorApi.getCountries(this.sourceCountriesCode).then(({ data }) => {
        const normalizedData = normalize(data, [schema.sourceCountry]);
        this.sourceCountriesOrder = normalizedData.result;
        this.sourceCountries = normalizedData.entities.sourceCountries;
        this.destinationCountries =
          normalizedData.entities.destinationCountries;
        this.loading = false;
      });
    },
    getTransferLimits() {
      SimulatorApi.getTransferLimits().then(({ data }) => {
        this.transferLimits = data;
      });
    },
    getExchangeRateList() {
      if (!this.selectedSourceCountry || !this.selectedDestinationCountry) {
        return;
      }

      SimulatorApi.getExchangeRates(
        this.selectedSourceCountry.id,
        this.selectedDestinationCountry.id
      ).then(({ data }) => {
        this.exchangeRateList = data;
        this.selectedDestinationCurrency = Object.keys(data)[0];
        this.setExchangeRate();
      });
    },
    restartAmounts() {
      this.sourceAmount = null;
    },
    setExchangeRate() {
      if (Object.keys(this.exchangeRateList).length === 0) {
        return;
      }

      this.selectedExchangeRate =
        getLastValidExchangeRateV2(
          this.exchangeRateList[this.selectedDestinationCurrency],
          this.sourceAmount
        ) || {};
    },
    handleSourceAmountInput(event) {
      this.sourceAmount = event.target.autonumeric.rawValue;
    },
    errorAmount() {
      if (
        this.sourceAmount > 0 &&
        this.sourceAmount < this.selectedTransferLimit.minimumAmount
      ) {
        return `El monto mínimo es ${AutoNumeric.format(
          this.selectedTransferLimit.minimumAmount,
          this.sourceAmountMask
        )}`;
      } else if (this.sourceAmount > this.selectedTransferLimit.maximumAmount) {
        return `El monto máximo es ${AutoNumeric.format(
          this.selectedTransferLimit.maximumAmount,
          this.sourceAmountMask
        )}`;
      }

      return false;
    }
  },
  watch: {
    selectedSourceCountry() {
      this.getSimulatorData();
      this.restartAmounts();
      this.selectedDestinationCountry =
        this.selectedDestinationCountries.find(
          country => country.id == this.selectedDestinationCountry.id
        ) || this.selectedDestinationCountries[0];
    },
    selectedDestinationCountry(val) {
      this.isPeru = val.name == "Perú";
      this.getSimulatorData();
      if (val !== null) {
        this.getExchangeRateList();
      }
    },
    sourceAmount() {
      this.setExchangeRate();
    },
    loading(val) {
      if (val === false) {
        this.selectedSourceCountry =
          this.sourceCountries[this.sourceCountriesOrder[0]] || {};
        this.selectedDestinationCountry =
          this.selectedDestinationCountries[0] || {};
      }
    },
    selectedDestinationCurrency() {
      this.setExchangeRate();
    }
  }
};
</script>
