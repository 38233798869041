<template>
  <div class="arcadi-page arcadi-transfer">
    <div class="arcadi-card">
      <div class="flex arcadi-card__title">
        {{ $t("buy_dollars_page.show_title") }}
        <img
          :src="images.order"
          class="inline align-middle ml-3"
          style="min-width: 40px; max-width: 40px"
        />
      </div>
      <div>
        <div
          class="flex flex-col justify-center mb-6 p-2 transfer-amount-container-body transfer-amount-container-result"
        >
          <div class="flex items-center mb-1">
            <div class="px-4">
              <img
                :src="sourceImagePath"
                class="inline align-middle flag-style"
                style="min-width: 34px; max-width: 34px"
              />
            </div>
            <div class="w-full px-3">
              <div class="mb-1">
                <span class="mb-1">{{ payMessage }}</span>
              </div>
              <div style="font-weight: 500; font-size: 24px">
                {{
                  AutoNumeric.format(order.source_amount, sourceAmountMask())
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col justify-center mb-4 p-2 transfer-amount-container-body transfer-amount-container-result"
      >
        <div class="flex items-center mb-1">
          <div class="px-4">
            <img
              :src="coinImage"
              class="inline align-middle flag-style"
              style="min-width: 34px; max-width: 34px"
            />
          </div>
          <div class="w-full px-3">
            <div class="mb-1">
              <span class="mb-1">{{ receiveMessage }}</span>
            </div>
            <div style="font-weight: 500; font-size: 24px">
              {{
                AutoNumeric.format(
                  order.destination_amount,
                  destinationAmountMask()
                )
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="ml-8 mb-1 font-color-primary">
        {{ buyDollarsMessage }}
      </div>
      <div>
        <div class="order-form__info-container space-y-2">
          <div class="order-form__info">
            <span>{{ $t("buy_dollars_page.exchange_rate") }}:</span>
            <span v-if="Number(order.rate) > 1" class="whitespace-no-wrap">
              1 {{ order.source_amount_currency }} =
              {{
                AutoNumeric.format(
                  exchangeRateFeeles() || 0,
                  destinationAmountMask()
                )
              }}
            </span>
            <span v-else class="whitespace-no-wrap">
              1 {{ order.destination_amount_currency }} =
              {{
                AutoNumeric.format(
                  1 / (exchangeRateFeeles() || 1),
                  sourceAmountMask()
                )
              }}
            </span>
          </div>
          <hr class="w-full text-gray-10% mb-2">
          <div class="order-form__info">
            <span>{{ $t("buy_dollars_page.commission") }}:</span>
            <span class="whitespace-no-wrap">
              {{ AutoNumeric.format(commission(), sourceAmountMask()) }}
            </span>
          </div>
        </div>
        <div class="flex justify-between font-footer">
          <div>
            {{ new Date(order.created_at).toLocaleDateString() }}
          </div>
          <div>
            <span>Id:</span>
            <span class="whitespace-no-wrap">
              {{ order.id }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutoNumeric from "autonumeric";
import { humanizeCurrency } from "../../../utils/money_utils";

export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      AutoNumeric,
      images: {
        order: require("../../../../assets/images/Menu-ComprarDolares.svg")
      }
    };
  },
  computed: {
    payMessage() {
      const message = {
        pending: this.$t("buy_dollars_page.pay_pending"),
        asigned: this.$t("buy_dollars_page.pay_pending"),
        executed: this.$t("buy_dollars_page.pay_executed"),
        other: this.$t("buy_dollars_page.pay_failed")
      };
      return message[this.order.state] || message.other;
    },
    receiveMessage() {
      const message = {
        pending: this.$t("buy_dollars_page.will_recieve"),
        asigned: this.$t("buy_dollars_page.will_recieve"),
        executed: this.$t("buy_dollars_page.executed"),
        other: this.$t("buy_dollars_page.failed")
      };
      return message[this.order.state] || message.other;
    },
    buyDollarsMessage() {
      const message = {
        pending: this.$t("buy_dollars_page.buy_dollars_pending"),
        asigned: this.$t("buy_dollars_page.buy_dollars_pending"),
        executed: this.$t("buy_dollars_page.buy_dollars_executed"),
        other: this.$t("buy_dollars_page.buy_dollar_failed")
      };
      return message[this.order.state] || message.other;
    },
    coinImage() {
      const image = {
        USDT: require("../../../../assets/images/coin_flags/USDT.svg"),
        USDC: require("../../../../assets/images/coin_flags/USDC.svg")
      };
      return image[this.order.destination_amount_currency] || image.USDT;
    },
    sourceImagePath() {
      return require(`../../../../assets/images/coin_flags/${this.order.source_amount_currency}.svg`);
    }
  },
  mounted() {
    console.log(this.order);
  },
  methods: {
    sourceAmountMask() {
      return {
        allowDecimalPadding: "floats",
        modifyValueOnWheel: false,
        decimalPlaces: 2,
        decimalPlacesRawValue: null,
        caretPositionOnFocus: "end",
        selectOnFocus: false,
        decimalCharacter: ",",
        digitGroupSeparator: ".",
        currencySymbol: ` ${humanizeCurrency(
          this.order.source_amount_currency
        ) || ""}`,
        currencySymbolPlacement: "s",
        emptyInputBehavior: "always"
      };
    },
    destinationAmountMask() {
      return {
        allowDecimalPadding: "floats",
        decimalPlaces: 2,
        decimalPlacesRawValue: null,
        decimalCharacter: ",",
        digitGroupSeparator: ".",
        currencySymbol: ` ${this.order.destination_amount_currency || ""}`,
        currencySymbolPlacement: "s",
        emptyInputBehavior: "always"
      };
    },
    commission() {
      return this.order.destination_amount > 0
        ? this.order.source_amount -
            this.order.source_amount * (1 - this.percentageCommission())
        : 0;
    },
    percentageCommission() {
      return this.order.fee;
    },
    exchangeRateFeeles() {
      return this.order.rate / (1 - this.percentageCommission());
    }
  }
};
</script>
<style scoped>
.transfer-amount-container-body {
  border-radius: 27px;
  border-left: 6px solid #611de9;
  background: #f9f9f9;
  height: 77px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.transfer-amount-container-result {
  height: 100px;
}

.transfer-amount-container-body-green {
  border-left: 6px solid #ccff00;
}

.font-footer {
  color: #999;
}

</style>
