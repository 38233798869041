<template>
  <div class="stepper-container">
    <div class="stepper">
      <div v-for="(stepNum, index) in Array.from(Array(totalSteps).keys())" :key="index" class="contents">
        <div :class="['step', { 'step-active': isStepActive(stepNum + 1) }]">
          <span class="material-icons-sharp">{{ actualSimbol(stepNum + 1) }}</span>
        </div>
        <div
          v-if="stepNum + 1 < totalSteps"
          :class="['line-stepper', { 'step-inactive': step <= stepNum + 1 }]"
        ></div>
      </div>
    </div>
  </div>
</template>

<style scoped>

:root {
  --stepper-background: rgba(228, 228, 228, 0.60)
}

[data-theme="dark"] {
  --stepper-background: #1D1C1F;
}


.stepper-container {
  margin-top: 20px;
  background-color: var(--stepper-background);
  border-radius: 15px;
  padding: 25px;
}

.stepper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step {
  color: #6c757d;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.line-stepper {
  flex: 1;
  height: 2px;
  background-color: var(--background-color-complementary);
}

.step-active {
  color: var(--background-color-complementary);
}

.completed {
  background-color: #28a745;
  color: #fff;
}

.step-inactive {
  background-color: #6c757d;
}
</style>

<script>
export default {
  name: "TransferStepper",
  props: {
    step: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
  },
  methods: {
    isStepActive(step) {
      return step <= this.step;
    },
    actualSimbol(step) {
      if (this.step == step) {
        return "radio_button_checked";
      } else if (this.step > step) {
        return "check_circle";
      } else {
        return "radio_button_unchecked";
      }
    },
  },
};
</script>
