<template>
  <div class="w-11/12 lg:w-1/2 mx-auto py-12">
    <h1 class="arcadi-bold text-center text-2xl font-medium mb-6">
      Preguntas frecuentes
    </h1>
    <div class="p-1">
      <div class="accordion">
        <div
          v-for="(question, key, index) in questions"
          :key="key"
          :class="[
            'accordion-item p-5 py-1',
            { 'accordion-item-active': isQuestionActive(index)}
          ]"
        >
          <input
            type="checkbox"
            :id="'section' + (index + 1)"
            class="accordion-input"
            @click="handlerQuestion(index)"
          />
          <label
            :for="'section' + (index + 1)"
            class="accordion-header cursor-pointer"
          >
            <div class="flex justify-between">
              <span class="font-medium text-black">{{ index + 1 }}. {{ question.title }}</span>

              <span class="material-icons-sharp text-black">
                {{ isQuestionActive(index) ? "expand_less" : "expand_more" }}
              </span>
            </div>
          </label>
          <div class="text-black accordion-content text-base font-normal">
            <p v-html="question.content"></p>
          </div>
        </div>
      </div>
      <div class="accordion-item p-5 py-4">
        <h2 class="arcadi-bold font-medium">
          {{ extra.title }}
        </h2>
        <p class="text-base font-normal">
          {{ extra.content }}
        </p>
        <div class="my-3">
          <a
            :href="whatsapp.link"
            target="_blank"
            class="text-base link-whatsapp p-2 text-center mt-2"
          >
            <img :src="whatsapp.image" class="mr-1 inline w-4 -mt-px" />
            {{ whatsapp.text }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Puedes agregar estilos adicionales según tus necesidades */

.accordion {
  overflow: hidden;
}

.accordion-item {
  border: none;
  margin-bottom: 2rem;
  border-radius: 27px;
  border-left: 6px solid #611de9;
  opacity: 0.9;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: none;
}

.accordion-item-active {
  border-left: 6px solid #00ff2a;
  box-shadow: none;
}

.accordion-input {
  display: none;
}

.accordion-header {
  display: block;
  padding: 0.75rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  cursor: pointer;
}

.accordion-content {
  display: none;
  padding: 0.75rem;
}
.accordion-input:checked + .accordion-header + .accordion-content {
  display: block;
}
.accordion-input:checked + .accordion-header {
  border-bottom: 1px solid #ddd;
}
.link-whatsapp {
  border-radius: 27px;
  border: 1px solid #000;
  background: #fff;
  box-shadow: 4px 4px 0px 0px #00ff2a;
}
</style>

<script>
export default {
  data() {
    return {
      extra: {
        title: "¿Tienes preguntas adicionales?",
        content:
          "Estamos aquí para ayudarte. No dudes en ponerte en contacto con nosotros a través de WhatsApp. Nuestro equipo responderá a la brevedad posible para brindarte la asistencia que necesitas 🫡."
      },
      activeQuestions: [],
      whatsapp: {
        image: require("../../../../assets/images/whatsapp-icono.svg"),
        link: "https://wa.me/56948428139",
        text: "Ir a Whatsapp"
      }
    };
  },
  computed: {
    questions() {
      return this.$t("frequent_questions")
    }
  },
  methods: {
    handlerQuestion(index) {
      if(this.activeQuestions.includes(index)) {
        this.activeQuestions = this.activeQuestions.filter((item) => item !== index)
      } else {
        this.activeQuestions.push(index)
      }
    },
    isQuestionActive(index) {
      return this.activeQuestions.includes(index)
    }
  }
};
</script>
