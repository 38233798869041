<template>
  <div class="container-arcadi">
    <div class="flex flex-col">
      <form
        :action="actionUrl"
        method="POST"
      >
        <csrf-input />
        <p class="flex flex-col mb-4">
          <label
            for="amount"
            class="mb-2"
          >
            Ingresa el monto que deseas abonar
          </label>
          <input
            type="tel"
            :rawvalue="sourceAmount"
            @input="handleSourceAmountInput"
            v-numeric="sourceAmountMask"
            inputmode="numeric"
            autocomplete="off"
            class="w-full arcadi-input border-gray-custom" />
          <input type="hidden" name="cost" v-model="sourceAmount" />
        </p>

        <p class="p-4 mb-4 -mt-4 rounded-lg shadow-md">
          ⚠️ Atención: Costo de la transacción = 2% + IVA
        </p>

        <p class="flex justify-center actions">
          <input
            type="submit"
            value="Confirmar"
            class="text-white arcadi-form__submit arcadi-button"
          >
        </p>
        <br>
        <div class="grid grid-cols-2 gap-4 text-base font-bold">
          <div>País destino</div>
          <div class="text-right">Monto mínimo</div>
        </div>
        <div id="minimun-container">
          <div class="card line" v-for="minimum in transferLimits" :key="minimum.destinationCountryId">
            <div class="grid grid-cols-2 gap-4 text-base leading-10">
              <div>
                <span class="overflow-visible whitespace-no-wrap" :key="minimum.destinationCountryId">
                  <img :src="getCountryPath(minimum.destinationCountryId)" class="inline align-middle" style="min-width: 34px; max-width: 34px;" />
                  <span class="pl-4">
                    <button type="button" id="show-modal" @click="showModalCountry(minimum.destinationCountryId)">
                      <img :src="questionMarkSVG" class="inline align-middle" style="min-width: 34px; max-width: 34px;"/>
                    </button>
                  </span>
                  <span class="hidden ml-2 align-middle lg:inline">{{ getCountryName(minimum.destinationCountryId) }}</span>
                </span>

              </div>
              <div class="pl-8">
                <span>{{ AutoNumeric.format(minimum.minimumAmount, minimumAmountMask(minimum.currency)) }}</span>
              </div>
            </div>
          </div>
        </div>
      </form>
      <!-- use the modal component, pass in the prop -->
      <modal :show="showModal" @close="showModal = false" :idCountry="currentCountryIdModal" :countryPath="currentCountryPath">
        <template #header>

        </template>
      </modal>
    </div>
  </div>
</template>

<style>
.line {
  border-bottom: 1px solid #ebebeb;
}
</style>

<script>
import csrfInput from '../partials/csrf-input.vue';
import SimulatorApi from '../../../api/simulator';
import { humanizeCurrency } from '../../../utils/money_utils';
import AutoNumeric from 'autonumeric';
import Modal from './Modal.vue';

export default {
  beforeMount() {
    this.getCountries();
    this.getTransferLimits();
  },
  components: {
    csrfInput,
    Modal
  },
  props:{
    actionUrl: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      sourceAmount: 0,
      transferLimits: [],
      countries: [],
      AutoNumeric,
      showModal: false,
      currentCountryIdModal: 0,
      currentCountryPath: "",
      questionMarkSVG: require('../../../../assets/images/question-mark.svg')
    }
  },
  computed: {
    sourceAmountMask() {
      return {
        allowDecimalPadding: 'floats',
        modifyValueOnWheel: false,
        decimalPlaces: 2,
        decimalPlacesRawValue: null,
        caretPositionOnFocus: 'end',
        selectOnFocus: false,
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: '',
        currencySymbolPlacement: 's',
        emptyInputBehavior: 'always',
      }
    },
  },
  methods: {
    handleSourceAmountInput(event) {
      this.sourceAmount = Number(event.target.autonumeric.rawValue);
    },
    getTransferLimits() {
      SimulatorApi.getTransferLimits().then(({ data }) => {
        this.transferLimits = data.filter((item) => {
          return (item.currency == 'COP')
        })
      });
    },
    getCountries(){
      SimulatorApi.getAllCountries().then(({ data }) => {
        this.countries = data;
      });
    },
    getCountryName(countryId){
      const country = this.countries.filter((item) => {
        return (item.id == countryId)
      })
      return country.length > 0 ? country[0].name : "";
    },
    getCountryPath(countryId){
      const country = this.countries.filter((item) => {
        return (item.id == countryId)
      })
      return country.length > 0 ? country[0].urlPath : "";
    },
    minimumAmountMask(currencySymbol) {
      return {
        allowDecimalPadding: 'floats',
        decimalPlaces: 2,
        roundingMethod: 'F',
        decimalPlacesRawValue: null,
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbolPlacement: 's',
        emptyInputBehavior: 'always',
        readOnly: true,
        currencySymbol: ` ${humanizeCurrency(currencySymbol)}`,
      };
    },
    showModalCountry(countryId){
      this.currentCountryIdModal = countryId;
      this.currentCountryPath = this.getCountryPath(countryId);
      this.showModal = true;
    }
  },
};
</script>
