<template>
  <div :class="['w-auto currency-select', { 'currency-styled': styled }]">
    <v-select v-model="selectedCurrency" @input="handleSelect" :options="currencies" label="name" :clearable="false"
      :searchable="false">
      <template v-slot:[slotName]="currency" v-for="slotName in ['option', 'selected-option']">
        <div class="overflow-visible whitespace-no-wrap" :key="slotName">
          <img :src="require(`coin_flags/${currency.name}.svg`)" class="inline align-middle flag-style"
            style="min-width: 34px; max-width: 34px;" />
          <span class="ml-2 align-middle">{{ humanizeCurrency(currency.name) }}</span>
        </div>
      </template>
      <template #no-options>
        No hay monedas disponible
      </template>
    </v-select>
  </div>
</template>

<style scoped>
.currency-select>>>.vs__dropdown-toggle {
  width: fit-content;
  height: 50px;
  background-color: #FFF;
  border-radius: 0.8rem;
}

.currency-select>>>.vs__dropdown-menu {
  min-width: 100%;
  border-radius: 0.8rem;
}

.currency-select>>>.vs__actions {
  margin-right: 10px;
}

.currency-styled>>>.vs__dropdown-toggle {
  border-radius: 27px;
  border: 2px solid #000;
  background: #F9F9F9;
  box-shadow: 3px 3px 0px 0px #611DE9;
}
</style>

<script>
import { humanizeCurrency } from '../../../utils/money_utils'

export default {
  watch: {
    currencies: {
      handler(newCurrencies, oldCurrencies) {
        this.selectedCurrency = newCurrencies[0];
      },
      immediate: true
    }
  },
  beforeMount() {
    const urlParams = new URLSearchParams(window.location.search);

    this.selectedCurrency = this.value || urlParams.get('currency') || this.currencies[0];
  },
  props: {
    currencies: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
    },
    styled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      selectedCurrency: '',
      humanizeCurrency,
    };
  },
  methods: {
    handleSelect(_event) {
      if (this.value) {
        this.$emit('input', this.selectedCurrency)
        return
      }

      const url = new URL(window.location.href);
      url.searchParams.set('currency', this.selectedCurrency);

      window.location = url;
    },
  },
};
</script>
