import api from './index';

export default {
  getTerms() {
    return api({
      method: 'get',
      url: '/api/v2/investment_rate_terms'
    });
  },
  getInvestmentRates(currency, term) {
    return api({
      method: 'get',
      url: '/api/v2/investment_rates',
      params: {
        currency,
        term
      }
    });
  },
  getInvestmentRatesByCurrency(currency) {
    return api({
      method: 'get',
      url: '/api/v2/investment_rates_by_currency',
      params: {
        currency
      }
    });
  },
};
