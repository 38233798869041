import AutoNumeric from 'autonumeric';
import { humanizeCurrency } from '../utils/money_utils';

const moneyMask = (currency) => {
  return {
    allowDecimalPadding: 'floats',
    modifyValueOnWheel: false,
    decimalPlaces: currency === 'CLP' ? 0 : 2,
    decimalPlacesRawValue: null,
    caretPositionOnFocus: 'end',
    selectOnFocus: false,
    decimalCharacter: ',',
    digitGroupSeparator: '.',
    currencySymbol: ` ${humanizeCurrency(currency) || ''}`,
    currencySymbolPlacement: 's',
    emptyInputBehavior: 'always',
  };
};

const moneyFormat = (number, currency) => {
  return AutoNumeric.format(number, moneyMask(currency))
};

export {
  moneyFormat,
};
