import { schema } from 'normalizr';

const destinationCountry = new schema.Entity('destinationCountries');

const sourceCountry = new schema.Entity('sourceCountries', {
  destinationCountries: [destinationCountry],
});

export default {
  destinationCountry,
  sourceCountry,
};
