<template>
  <input
    type="hidden"
    name="authenticity_token"
    :value="token"
  >
</template>

<script>
import { csrfToken } from 'rails-ujs';

export default {
  data() {
    return { token: csrfToken() };
  },
};
</script>
