<template>
  <div>
    <CurrencySelect :currencies="currencies" v-model="sourceCurrency" :otro="sourceCurrency" />

    <input type="hidden" name="source_currency" :value="sourceCurrency" />

    <div class="px-8 py-2 mt-2 bg-white shadow-md rounded-2xl arcadi-dashboard-movements__movements">
      <div v-if="this.movements && this.movements.length > 0">
        <div v-for="movement in movements" :key="movement.id" class="py-6 arcadi-dashboard-movement">
          <div class="arcadi-dashboard-movement__info">
            <div class="arcadi-dashboard-movement__type">
              {{ movement.operationName }}
              <WalletHashPopup v-if="movement.tatumID" :tatum-id="movement.tatumID" />
            </div>
            <div class="arcadi-dashboard-movement__time">{{movement.date}}</div>
          </div>
          <div class="arcadi-dashboard-movement__amounts">
            <div class="arcadi-dashboard-movement__delta"
            :class="[movement.negative ? 'arcadi-dashboard-movement__delta--negative' : 'arcadi-dashboard-movement__delta--positive']">
              {{ movement.negative ? '-' : '+' }} {{ movement.amount }}
            </div>
            <div class="arcadi-dashboard-movement__total">
              {{ movement.balance }}
            </div>
          </div>
        </div>
      </div>
      <div v-else class="arcadi-dashboard-movements__message">
        Sin movimientos
      </div>
    </div>
  </div>
</template>

<script>
import CurrencySelect from '../partials/currency-select';
import MovementsApi from '../../../api/movements';
import WalletHashPopup from '../wallet/hash-popup.vue';

export default {
  beforeMount() {
    this.sourceCurrency = this.currencies[0];

    this.updateTable();
  },
  components: {
    CurrencySelect,
    WalletHashPopup
  },
  props: {
    currencies: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sourceCurrency: '',
      movements: [],
    };
  },
  watch: {
    // whenever sourceCurrency changes, this function will run
    sourceCurrency(newSourceCurrency, oldSourceCurrency) {
      if (newSourceCurrency != oldSourceCurrency) {
        this.updateTable();
      }
    }
  },
  methods: {
    updateTable() {
      MovementsApi.getMovements(this.sourceCurrency, 5).then(({ data }) => {
        this.movements = data;
      });
    }
  }
};
</script>
