import IMask from 'imask';

const PRECISION = 7;

function getLastValidExchangeRateV2(exchangeRatesList, amount) {
  return exchangeRatesList
    .filter(({
      minimumAmountLimit: minimum,
      maximumAmountLimit: maximum,
    }) => (amount >= minimum || minimum === 0) && (amount <= maximum || maximum === 0))
    .reduce((finalRate, currentRate) => {
      if (!finalRate) return currentRate;
      if (currentRate.minimumAmountLimit > finalRate.minimumAmountLimit) return currentRate;
      if (currentRate.minimumAmountLimit === finalRate.minimumAmountLimit) {
        if (currentRate.createdAt > finalRate.createdAt) return currentRate;
      }

      return finalRate;
    }, null);
}

function getLastValidExchangeRate(exchangeRatesList, amount) {
  return exchangeRatesList
    .filter(({
      minimum_amount_limit: minimum,
      maximum_amount_limit: maximum,
    }) => (amount >= minimum || minimum === 0) && (amount <= maximum || maximum === 0))
    .reduce((finalRate, currentRate) => {
      if (!finalRate) return currentRate;
      if (currentRate.minimum_amount_limit > finalRate.minimum_amount_limit) return currentRate;
      if (currentRate.minimum_amount_limit === finalRate.minimum_amount_limit) {
        if (currentRate.created_at > finalRate.created_at) return currentRate;
      }

      return finalRate;
    }, null);
}

function destinationMaskOptions({
  iso_code: isoCode,
}, precision = PRECISION) {
  const rightSymbol = isoCode === 'VES' ? 'Bs' : isoCode;

  return {
    mask: `num ${rightSymbol}`,
    lazy: false,
    blocks: {
      num: {
        signed: false,
        mask: Number,
        thousandsSeparator: '.',
        radix: ',',
        scale: precision,
      },
    },
  };
}

function maskOptions(currencyOptions, exchange_rate = false) {
  const rightSymbol = currencyOptions.isoCode === 'VES' ? 'Bs' : currencyOptions.isoCode;

  return {
    mask: `num ${rightSymbol}`,
    lazy: false,
    blocks: {
      num: {
        signed: false,
        mask: Number,
        thousandsSeparator: '.',
        radix: ',',
        scale: exchange_rate ? PRECISION : currencyOptions.exponent,
        subunitToUnit: currencyOptions.subunitToUnit,
      },
    },
  };
}

function createMask(object, currencyOptions, exchange_rate = false) {
  return IMask(object, maskOptions(currencyOptions, exchange_rate));
}

function updateMask(mask, currencyOptions, exchange_rate = false) {
  return mask.updateOptions(maskOptions(currencyOptions, exchange_rate));
}

/* eslint-disable new-cap */
function createSourceMask(sourceAmount) {
  return IMask(sourceAmount, {
    mask: `num ${window.application.BASE_CURRENCY}`,
    lazy: false,
    blocks: {
      num: {
        signed: false,
        mask: Number,
        thousandsSeparator: '.',
        radix: ',',
        scale: PRECISION,
      },
    },
  });
}

function createDestinationMask(destinationAmount, selectedCurrencyInfo, precision = PRECISION) {
  return IMask(destinationAmount, destinationMaskOptions(selectedCurrencyInfo, precision));
}
/* eslint-enable new-cap */

export {
  PRECISION,
  getLastValidExchangeRateV2,
  getLastValidExchangeRate,
  createSourceMask,
  createDestinationMask,
  destinationMaskOptions,
  createMask,
  maskOptions,
  updateMask
};
