<template>
  <div class="order-vue arcadi-card">
    <form id="new-order-form" method="POST" action="/orders" @submit.prevent="wizardProgress">
      <csrf-input />
      <div class="mb-1">
        <span v-show="confirmMode" @click="setConfirmMode()"
          class="cursor-pointer arcadi-card__icon-back material-icons-sharp">arrow_back</span>
        <div class="arcadi-card__title flex" v-show="!confirmMode">
          {{ $t("buy_dollars_page.title") }}
          <img :src="images.cotizaEnvio" class="w-10 ml-4" />
        </div>
        <div class="arcadi-card__title flex" v-show="confirmMode">
          {{ $t("buy_dollars_page.confirm_title") }}
          <img :src="images.cotizaEnvio" class="w-10 ml-4" />
        </div>
        <div class="pb-2">
          <transfer-stepper :step="actualStep" :total-steps="2" />
        </div>
        <div class="arcadi-card__lead" v-show="!confirmMode">
          {{ $t("buy_dollars_page.instructions") }}
        </div>
        <div class="arcadi-card__lead" v-show="confirmMode">
          {{ $t("buy_dollars_page.confirm_instructions") }}
        </div>
        <div class="flex justify-between items-center mt-5 mb-2 ml-4">
          <span class="font-color-primary" v-show="!confirmMode">
            {{ $t("buy_dollars_page.amount_payable") }}:
          </span>
          <button v-show="!confirmMode" type="button" @click="handleUseMaxBalance" class="button-arcadi-simulator-all">
            {{ $t("simulator_page.use_all") }}
          </button>
        </div>
      </div>
      <input type="hidden" name="source_amount" v-model="sourceAmount" />
      <input type="hidden" name="exchange_rate_id" v-model="selectedExchangeRate.id" />
      <div v-if="!confirmMode" class="flex flex-col transfer-amount-container-body"
        :class="[{ 'mb-4': errorAmount() }]">
        <div class="flex">
          <v-select v-model="selectedSourceCountry" :options="sourceCountriesOrder.map(countryId => sourceCountries[countryId])
      " label="name" :clearable="false" :searchable="false" class="flex items-center" disabled>
            <template v-slot:[slotName]="country" v-for="slotName in ['option', 'selected-option']">
              <span class="overflow-visible whitespace-no-wrap" :key="slotName">
                <img :src="country.urlPath" class="inline align-middle flag-style"
                  style="min-width: 34px; max-width: 34px" />
              </span>
            </template>
            <template #no-options>
              <span></span>
            </template>
          </v-select>
          <div class="w-full">
            <input :readonly="confirmMode" type="tel" :rawvalue="sourceAmount" @input="handleSourceAmountInput"
              v-numeric="sourceAmountMask" inputmode="numeric" autocomplete="off"
              class="w-full rounded-right arcadi-input border-gray-custom shadow-none bg-transparent"
              style="height: 77px; font-weight: 500; font-size: 24px" />
          </div>
        </div>
        <span v-if="errorAmount()" class="ml-2 text-sm text-red-400">
          {{ errorAmount() }}
        </span>
      </div>
      <div v-if="!confirmMode" class="flex text-sm pt-3 font-color-primary ml-4">
        <span class="font-semibold">{{ $t("buy_dollars_page.current_balance") }}:</span>
        <format-amount v-if="selectedSourceCurrency" :amount="availableBalance ? availableBalance : 0"
          :currency="selectedSourceCurrency"
          :maximumFractionDigits="selectedSourceCountry.currency === 'CLP' ? 0 : 2" />
      </div>
      <div v-else
        class="flex flex-col justify-center mb-6 p-2 transfer-amount-container-body transfer-amount-container-result">
        <div class="flex items-center mb-1">
          <div class="px-4">
            <img :src="selectedSourceCountry.urlPath" class="inline align-middle flag-style"
              style="min-width: 34px; max-width: 34px" />
          </div>
          <div class="w-full px-3">
            <span v-show="confirmMode">{{ $t("buy_dollars_page.pay") }}</span>
            <div style="font-weight: 500; font-size: 24px">
              {{ AutoNumeric.format(sourceAmount, sourceAmountMask) }}
            </div>
          </div>
        </div>
      </div>
      <div class="ml-4 mb-1 mt-4">
        <span v-show="!confirmMode" class="font-color-primary">{{ $t("buy_dollars_page.will_recieve") }}:</span>
      </div>
      <div v-if="!confirmMode"
        class="flex flex-col justify-center mb-3 transfer-amount-container-body transfer-amount-container-body-green">
        <div class="flex">
          <v-select v-model="selectedDestinationCountry" :options="selectedDestinationCountries" appendToBody
            label="name" class="flex items-center" :clearable="false" :searchable="false" :disabled="confirmMode">
            <template v-slot:[slotName]="country" v-for="slotName in ['option', 'selected-option']">
              <span class="overflow-visible whitespace-no-wrap" :key="slotName">
                <img :src="country.urlPath" class="inline align-middle flag-style"
                  style="min-width: 34px; max-width: 34px" />
              </span>
            </template>
            <template v-slot:no-options>
              <span></span>
            </template>
          </v-select>
          <div class="flex item-center">
            <format-amount :amount="destinationAmount" :currency="selectedDestinationCurrency"
              :maximumFractionDigits="selectedDestinationCurrency.currency === 'CLP' ? 0 : 2"
              :numberStyle="'font-weight: 500; font-size: 24px'" />
          </div>
        </div>
      </div>
      <div v-else
        class="flex flex-col justify-center mb-6 p-2 transfer-amount-container-body transfer-amount-container-body-green transfer-amount-container-result">
        <div class="flex items-center mb-1">
          <div class="px-4">
            <img :src="selectedDestinationCountry.urlPath" class="inline align-middle flag-style"
              style="min-width: 34px; max-width: 34px" />
          </div>
          <div class="w-full px-3">
            <span class="px-1" v-show="confirmMode">{{
      $t("buy_dollars_page.will_recieve")
    }}</span>
            <div>
              <format-amount :amount="destinationAmount" :currency="selectedDestinationCurrency"
                :maximumFractionDigits="selectedDestinationCountry.currency === 'CLP' ? 0 : 2"
                :numberStyle="'font-weight: 500; font-size: 24px'" />
            </div>
          </div>
        </div>
      </div>
      <div v-show="confirmMode">
        <div class="mt-8 text-center font-color-complementary">
          {{ $t("buy_dollars_page.buy_dollars") }}
          {{ selectedExchangeRateCurrency }}.
        </div>
      </div>
      <div class="order-form__info-container space-y-2 text-sm lg:text-base">
        <div class="order-form__info">
          <span class="font-normal">
            {{ $t("buy_dollars_page.exchange_rate") }}:
          </span>
          <span v-if="Number(selectedExchangeRate.rate) > 1" class="flex font-normal whitespace-no-wrap">
            1 {{ selectedExchangeRateCurrency }} =
            <format-amount :amount="1 / (selectedExchangeRateFeeles || 1)" :currency="selectedSourceCountry.currency"
              :maximumFractionDigits="selectedSourceCountry.currency === 'CLP' ? 0 : 2" />
          </span>
          <span v-else class="flex font-normal whitespace-no-wrap">
            1 {{ selectedExchangeRateCurrency }} =
            <format-amount :amount="1 / (selectedExchangeRateFeeles || 1)"
              :currency="this.selectedSourceCountry.currency" :maximumFractionDigits="2" />
          </span>
        </div>
        <hr class="w-full text-gray-10% my-2" />
        <div class="order-form__info">
          <span class="font-normal">
            {{ $t("buy_dollars_page.commission") }}:
          </span>
          <span v-if="selectedSourceCountry.currency" class="font-normal">
            <format-amount :amount="commission" :currency="selectedSourceCountry.currency"
              :maximumFractionDigits="selectedSourceCountry.currency === 'CLP' ? 0 : 2" />
          </span>
        </div>
      </div>
      <div v-show="!confirmMode">
        <div class="flex items-center">
          <div class="mr-4">
            <input type="checkbox" required v-model="declarationChecked" />
          </div>
          <div class="font-color-primary text-xs">
            {{ $t("buy_dollars_page.consent") }}
          </div>
        </div>
      </div>
      <div v-show="confirmMode" class="mb-4">
        <button type="submit" id="confirm" class="text-white shadow-md arcadi-form__submit arcadi-button focus:outline-none"
          :disabled="buttonDisabled">
          {{ $t("buy_dollars_page.confirm_button") }}
        </button>
      </div>
      <div v-show="!confirmMode" class="mb-4">
        <button type="submit" id="next" class="text-white shadow-md arcadi-form__submit arcadi-button focus:outline-none"
          :disabled="buttonDisabled">
          {{ $t("buy_dollars_page.next_button") }}
        </button>
      </div>
    </form>
  </div>
</template>

<style scoped>
.v-select {
  min-width: 65px;
}

.vs__selected-options {
  min-width: 35px;
  margin-left: 10px;
  margin-right: 10px;
}

.vs--disabled .vs__search {
  background-color: unset;
}

.order-vue .vs--disabled div.vs__dropdown-toggle {
  background-color: #fff !important;
}

.vs--single.vs--open .vs__selected {
  position: relative;
}

.vs__dropdown-toggle,
.vue__arcadi-input {
  height: 45px;
}

.order-vue>>>.vs__dropdown-toggle {
  border: none;
  border-radius: 0;
  background-color: transparent;
}

.vs__dropdown-menu {
  min-width: 65px;
  width: auto;
}

.vs__dropdown-menu li {
  padding: 4px 0 4px 10px;
}

.vs__open-indicator {
  transform: scale(0.8);
}

.vs__search {
  position: absolute;
}

.order-vue .vs__dropdown-toggle,
.order-vue .vue__arcadi-input {
  height: 100%;
  -webkit-border-radius: 0.6rem 0 0 0.6rem;
  -moz-border-radius: 0.6rem 0 0 0.6rem;
  border-radius: 0.6rem 0 0 0.6rem;
}

.transfer-amount-container-body {
  border-radius: 27px;
  border-left: 6px solid #611de9;
  background: #f9f9f9;
  height: 77px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.transfer-amount-container-result {
  height: 100px;
}

.transfer-amount-container-body-green {
  border-left: 6px solid #ccff00;
}
</style>

<script>
import { normalize } from "normalizr";
import AutoNumeric from "autonumeric";
import { humanizeCurrency } from "../../../utils/money_utils";
import csrfInput from "../partials/csrf-input.vue";
import schema from "../../../schema";
import SimulatorApi from "../../../api/simulator";
import { getLastValidExchangeRateV2 } from "../../../utils/exchange_rates";
import TransferStepper from "../transfers/transfer-stepper.vue";
import FormatAmount from "../amount/format-amount.vue";

export default {
  beforeMount() {
    this.getCountryAssetRates();
    this.getTransferLimits();
  },
  components: {
    csrfInput,
    TransferStepper,
    FormatAmount
  },
  props: {
    availableBalance: {
      type: Number,
      required: true
    }
  },
  data: () => {
    return {
      selectedSourceCountry: {},
      selectedDestinationCountry: {},
      selectedExchangeRate: {},
      sourceCountriesOrder: [],
      sourceCountries: [],
      destinationCountries: [],
      loading: true,
      confirmMode: false,
      sourceAmount: 0,
      selectedDestinationCurrency: "",
      sourceAmountError: false,
      transferLimits: [],
      declarationChecked: false,
      AutoNumeric,
      images: {
        cotizaEnvio: require("../../../../assets/images/Menu-ComprarDolares.svg")
      }
    };
  },
  computed: {
    actualStep() {
      return this.confirmMode ? 2 : 1;
    },
    buttonDisabled() {
      return (
        !this.declarationChecked || this.sourceAmountError || this.sourceAmount <= 0
      );
    },
    selectedDestinationCountries() {
      if (Object.values(this.selectedSourceCountry).length > 0) {
        return Object.values(this.selectedSourceCountry.destinationCountries)
          .length > 0
          ? this.selectedSourceCountry.destinationCountries.map(
            id => this.destinationCountries[id]
          )
          : [];
      }

      return [];
    },
    selectedTransferLimit() {
      return (
        this.transferLimits.find(transferLimit => {
          return (
            transferLimit.sourceCountryId == this.selectedSourceCountry.id &&
            transferLimit.destinationCountryId ==
            this.selectedDestinationCountry.id
          );
        }) || {}
      );
    },
    sourceAmountMask() {
      return {
        allowDecimalPadding: "floats",
        modifyValueOnWheel: false,
        decimalPlaces: this.selectedSourceCountry.currency === "CLP" ? 0 : 2,
        decimalPlacesRawValue: null,
        caretPositionOnFocus: "end",
        selectOnFocus: false,
        decimalCharacter: this.$i18n.locale === 'en' ? "." : ",",
        digitGroupSeparator: this.$i18n.locale === 'en' ? "," : ".",
        currencySymbol: ` ${humanizeCurrency(this.selectedSourceCurrency) ||
          ""}`,
        currencySymbolPlacement: "s",
        emptyInputBehavior: "always"
      };
    },
    destinationAmountMask() {
      return {
        allowDecimalPadding: "floats",
        decimalPlaces: 2,
        decimalPlacesRawValue: null,
        decimalCharacter: this.$i18n.locale === 'en' ? "." : ",",
        digitGroupSeparator: this.$i18n.locale === 'en' ? "," : ".",
        currencySymbol: ` ${this.selectedExchangeRateCurrency || ""}`,
        currencySymbolPlacement: "s",
        emptyInputBehavior: "always",
        readOnly: true
      };
    },
    selectedSourceCurrency() {
      if (this.selectedSourceCountry.currency === "VES") {
        return "Bs";
      }

      return this.selectedSourceCountry.currency;
    },
    selectedExchangeRateCurrency() {
      if (this.selectedDestinationCurrency.toUpperCase() === "VES") {
        return "Bs";
      }

      return this.selectedDestinationCurrency.toUpperCase();
    },
    destinationAmount() {
      if (Object.keys(this.selectedExchangeRate).length === 0) {
        return 0;
      }

      return this.sourceAmount * Number(this.selectedExchangeRate.rate);
    },
    commission() {
      return this.destinationAmount > 0
        ? this.sourceAmount -
        this.sourceAmount * (1 - this.percentageCommission)
        : 0;
    },
    percentageCommission() {
      return this.selectedExchangeRate.fee;
    },
    selectedExchangeRateFeeles() {
      return (
        Number(this.selectedExchangeRate.rate) / (1 - this.percentageCommission)
      );
    }
  },
  methods: {
    setConfirmMode() {
      this.confirmMode = false;
    },
    wizardProgress(event) {
      if (!event.target.checkValidity() || this.sourceAmountError) {
        document
          .getElementById("source-amount-warning")
          .classList.remove("hidden");
      } else if (!this.confirmMode) {
        this.confirmMode = true;
      } else {
        event.target.submit();
      }
    },
    getCountryAssetRates() {
      SimulatorApi.getCountryAssetRates().then(({ data }) => {
        const normalizedData = normalize(data, [schema.sourceCountry]);
        this.sourceCountriesOrder = normalizedData.result || [];
        this.sourceCountries = normalizedData.entities.sourceCountries || [];
        this.destinationCountries =
          normalizedData.entities.destinationCountries || [];
        this.loading = false;
      });
    },
    setExchangeRate() {
      if (Object.keys(this.exchangeRateList).length === 0) {
        return;
      }

      this.selectedExchangeRate =
        getLastValidExchangeRateV2(
          this.exchangeRateList[this.selectedDestinationCurrency],
          this.sourceAmount
        ) || {};
    },
    getExchangeRateList() {
      if (!this.selectedSourceCountry || !this.selectedDestinationCountry) {
        return;
      }

      SimulatorApi.getExchangeRates(
        this.selectedSourceCountry.id,
        this.selectedDestinationCountry.id
      ).then(({ data }) => {
        this.exchangeRateList = data;
        this.selectedDestinationCurrency = Object.keys(data)[0];
        this.setExchangeRate();
      });
    },
    handleSourceAmountInput(event) {
      this.sourceAmount = Number(event.target.autonumeric.rawValue);
    },
    handleUseMaxBalance(event) {
      this.sourceAmount = this.availableBalance
    },
    exchangeRateMask(currencySymbol) {
      return {
        allowDecimalPadding: "floats",
        decimalPlaces: 2,
        roundingMethod: "F",
        decimalPlacesRawValue: null,
        decimalCharacter: this.$i18n.locale === 'en' ? "." : ",",
        digitGroupSeparator: this.$i18n.locale === 'en' ? "," : ".",
        currencySymbolPlacement: "s",
        emptyInputBehavior: "always",
        readOnly: true,
        currencySymbol: ` ${humanizeCurrency(currencySymbol)}`
      };
    },
    errorAmount() {
      if (
        this.sourceAmount > 0 &&
        this.sourceAmount < this.selectedTransferLimit.minimumAmount
      ) {
        this.sourceAmountError = true;
        return `${this.$t("restrictions.minimum_ammount")} ${AutoNumeric.format(
          this.selectedTransferLimit.minimumAmount,
          this.sourceAmountMask
        )}`;
      } else if (this.sourceAmount > this.selectedTransferLimit.maximumAmount) {
        this.sourceAmountError = true;
        return `${this.$t("restrictions.maximum_ammount")} ${AutoNumeric.format(
          this.selectedTransferLimit.maximumAmount,
          this.sourceAmountMask
        )}`;
      } else if (this.sourceAmount > this.availableBalance) {
        this.sourceAmountError = true;
        return this.$t("restrictions.deposit_more");
      }
      this.sourceAmountError = false;

      return false;
    },
    getTransferLimits() {
      SimulatorApi.getTransferLimits().then(({ data }) => {
        this.transferLimits = data;
      });
    }
  },
  watch: {
    selectedSourceCountry() {
      this.selectedDestinationCountry =
        this.selectedDestinationCountries.find(
          country => country.id == this.selectedDestinationCountry.id
        ) || this.selectedDestinationCountries[0];
    },
    sourceAmount() {
      this.setExchangeRate();
    },
    selectedDestinationCountry(val) {
      this.getCountryAssetRates();
      if (val !== null) {
        this.getExchangeRateList();
      }
    },
    loading(val) {
      if (val === false) {
        this.selectedSourceCountry =
          this.sourceCountries[this.sourceCountriesOrder[0]] || {};
        this.selectedDestinationCountry =
          this.selectedDestinationCountries[0] || {};
      }
    },
    selectedDestinationCurrency() {
      this.setExchangeRate();
    }
  }
};
</script>
