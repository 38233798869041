import 'vue-select/dist/vue-select.css';

import Vue from 'vue/dist/vue.esm';
import VueI18n from 'vue-i18n'

import vSelect from 'vue-select';
import AutoNumeric from 'autonumeric';
import VueCountryCode from "vue-country-code";

import '../../assets/stylesheets/application.scss';
import '../css/application.scss';

import en from '../locales/en.json';
import es from '../locales/es.json';

import '../header';
import '../forms';
import './money_input';
import readySharedModals from './shared_modal'; './shared_modal.js';

import Simulator from '../components/views/partials/simulator';
import FrequentQuestions from '../components/views/partials/frequent-questions';
import OrderNewForm from '../components/views/orders/order-new-form';
import OrderShow from '../components/views/orders/order-show';
import CurrencySelect from '../components/views/partials/currency-select';
import BalanceSelector from '../components/views/transfers/balance-selector';
import TransferNewForm from '../components/views/transfers/transfer-new-form';
import NewBalanceSelector from '../components/views/transfers/new-balance-selector';
import TransferStepper from '../components/views/transfers/transfer-stepper.vue';
import AddBalance from '../components/views/tpaga/add-balance';
import CopTransfer from '../components/views/top_up/cop-transfer';
import WalletHashPopup from '../components/views/wallet/hash-popup';
import YourMovements from '../components/views/dashboard/your-movements';
import CountryDialcodeSelect from '../components/views/country-code-select/country-dialcode-select';
import ShowHidePasswordEyeButton from '../components/views/show-hide-password-eye-button/show-hide-password-eye-button';
import InvestmentNewForm from '../components/views/investments/investment-new-form';
import ModalDashboard from '../components/views/dashboard/modal-dashboard';
import FlowForm from '../components/views/deposit/flow-form';
import DestinationAccountForm from '../components/views/forms/destination-account-form';


Vue.directive('numeric', {
  inserted: (el, binding) => {
    el.autonumeric = new AutoNumeric(el, binding.value || {})
  },
  update: (el, binding) => {
    setTimeout(() => {
      el.autonumeric.set(Number(el.attributes.rawvalue ? el.attributes.rawvalue.value : 0), binding.value || {})
    }, 0);
  },
})
Vue.use(VueI18n);
const messages = { en, es };
const locale = document.documentElement.lang;

const i18n = new VueI18n({
  locale: locale,
  messages,
  numberFormats: {
    en: {
      separator: ',',
      decimal: '.',
      currency: {
        style: 'currency', currency: 'USD', currencyDisplay: 'symbol'
      }
    },
    es: {
      separator: '.',
      decimal: ',',
      currency: {
        style: 'currency', currency: 'CLP', currencyDisplay: 'symbol', notation: 'standard', useGrouping: true
      }
    },

  },
});

Vue.component('v-select', vSelect);
Vue.use(VueCountryCode);

document.addEventListener('DOMContentLoaded', () => {
  readySharedModals();

  if (document.getElementById('vue-app')) {
    const app = new Vue({
      el: '#vue-app',
      components: {
        Simulator, FrequentQuestions, OrderNewForm, OrderShow, CurrencySelect, BalanceSelector, TransferNewForm, NewBalanceSelector, TransferStepper,
        AddBalance, CopTransfer, WalletHashPopup, YourMovements, CountryDialcodeSelect, ShowHidePasswordEyeButton, InvestmentNewForm,
        ModalDashboard, FlowForm, DestinationAccountForm
      },
      i18n,
    });

    return app;
  }
});
