<template>
  <div>
    <vue-country-code @onSelect="onSelect" :preferredCountries="preferredCountries">
    </vue-country-code> <span class="font-color-primary">+{{ this.selectedDialCode }}</span>
    <input type="hidden" name="dial_code" :value="selectedDialCode" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedDialCode : '',
      preferredCountries: [ 'CL', 'CO', 'VE' ]
    };
  },
  methods: {
     onSelect({name, iso2, dialCode}) {
       this.selectedDialCode = dialCode;
     },
  },
}
</script>

<style>
.vue-country-select {
  height: 45px;
}
</style>
