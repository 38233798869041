<script>
export default {
  props: {
    show: Boolean,
    numeroDocumento: String
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div>
        <div class="modal-container">
          <div class="modal-close">
            <slot name="close-button">
              <button class="modal-default-button text-black" @click="$emit('close')">
                X
              </button>
            </slot>
          </div>
          <div class="modal-header text-black">
            <h3>Ejemplo:</h3>
          </div>

          <div class="modal-body modal-header text-black">
            <p>
              El Nº de identificación que registraste en Arcadi es
              {{ numeroDocumento }}.
            </p>
            <p>
              Por lo tanto, si deseas abonar a tu saldo en Arcadi, por ejemplo,
              $50.000 COP, debes transferirnos $50.00{{
                numeroDocumento.slice(-1)
              }}
              COP o $49.99{{ numeroDocumento.slice(-1) }} COP.
            </p>
            <p>De esta forma identificaremos fácilmente tu pago.</p>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  font-weight: bold;
}

.modal-body {
  margin: 20px 0;
}

.modal-body p {
  font-size: small;
}

.modal-body p span {
  font-weight: bold;
}

.modal-default-button {
  float: right;
  font-size: larger;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.close {
  border: none;
  font-size: 30px;
  margin-left: 100px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}
</style>
